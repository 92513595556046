.menu li a {
  padding: rem-calc(2);

  &.active {
    color: $dark-blue;
  }
}

a:hover, a:focus, a:active, a:visited {
  text-decoration: none;
}

.navigation {
  background: $white;
  border-bottom: 1px solid #E8E8E8;
  z-index: 200;
  display: block;
  position: fixed;
  width: 100%;

  li {
    margin-right: rem-calc(40);
    list-style-type: none;
    display: inline-block;
    font-size: rem-calc(16);
  }
}

.navigation-links {
  font-family: 'Apercu Regular',-apple-system, "system-ui", "Segoe font-family: 'Apercu Regular',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  text-align: center;
  font-size: rem-calc(16);
  color: $dark-grey;
  border-top: 1px solid #e3e3e3;

  @include breakpoint(medium up) {
    border: 1px solid transparent;
  }

  @include breakpoint(large up) {
    text-align: right;
  }

  ul {
    text-align: center;
    margin: rem-calc(12 0 12 0) ;

    @include breakpoint(medium up) {
      margin: rem-calc(18);
      text-align: right;
    }
  }

  li {
    margin: 0px 8px;

    @include breakpoint(medium up) {
      margin-left: 16px;
    }
  }

  li a {
    margin: 0px;
    color: $dark-grey;
    transition: all .2s ease-in-out;

    &:hover {
      color: $pink;
    }
  }
}

.mandi-logo {
  text-align: center;
  padding: rem-calc(12);

  @include breakpoint(medium) {
    float: left;
    padding-top: rem-calc(16);
    padding-left: rem-calc(32);
  }

  a  {
    font-family: 'Apercu Bold',-apple-system, "system-ui", "Segoe font-family: 'Apercu Bold',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    color: $pink;
    font-size: rem-calc(20);

    @include breakpoint(medium) {
      text-align: right;
    }

    &:hover {
      color: $dark-blue;
      transition: all 0.3s ease-in-out;
    }
  }
}

//Mobile
.off-canvas-menu {
  font-size: rem-calc(18);
  font-weight: 700;

  ul {
    padding: rem-calc(8);
  }

  li {list-style-type: none;}

  a {
    color: $dark-grey;
    line-height: 300%;

    &:focus {
      text-decoration: none;
    }

    &.active {
      color: $dark-blue;
    }
  }

  hr {
    margin: rem-calc(12) auto rem-calc(24) auto;
  }

  .off-canvas-menu-contact {
    line-height: 300%;
    color: $dark-blue;

    img {margin-right: rem-calc(12);}
  }
}

.navigation-mobile {
  z-index: 50;
  background: $white;
  display: block;
  position: fixed;
  width: 100%;
  height: rem-calc(120);
  border-bottom: 1px solid #E8E8E8;
  text-align: center;

  .off-canvas-button {
    margin: rem-calc(10);
    float: left;
    width: rem-calc(26);
    height: rem-calc(30);
    background: url('../img/navigation-icon.svg') no-repeat;
    background-size: cover;
  }
}

.off-canvas {
  display: none;

  @include breakpoint(small only) {
    display: inline;
  }
}

#contact-excel-information:focus {
  outline: none;
}
