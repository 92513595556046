@charset "UTF-8";
/**
 * Foundation for Sites by ZURB
 * Version 6.3.1
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Change the default font family in all browsers (opinionated).
     * 2. Correct the line height in all browsers.
     * 3. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */ }

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
  margin: 0; }

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
  display: block; }

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
  display: block; }

/**
     * Add the correct margin in IE 8.
     */
figure {
  margin: 1em 40px; }

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
     * Add the correct display in IE.
     */
main {
  display: block; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
     * Remove the outline on focused links when they are also active or hovered
     * in all browsers (opinionated).
     */
a:active,
a:hover {
  outline-width: 0; }

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
  font-weight: inherit; }

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
  font-weight: bolder; }

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
  font-style: italic; }

/**
     * Add the correct background and color in IE 9-.
     */
mark {
  background-color: #ff0;
  color: #000; }

/**
     * Add the correct font size in all browsers.
     */
small {
  font-size: 80%; }

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
  display: inline-block; }

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
  border-style: none; }

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
  overflow: hidden; }

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
     * Show the overflow in IE.
     */
button {
  overflow: visible; }

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
  /* 1 */
  text-transform: none; }

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  /**
       * Remove the inner border and padding in Firefox.
       */
  /**
       * Restore the focus styles unset by the previous rule.
       */ }
  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0; }
  button:-moz-focusring,
  [type="button"]:-moz-focusring,
  [type="reset"]:-moz-focusring,
  [type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText; }

/**
     * Show the overflow in Edge.
     */
input {
  overflow: visible; }

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
  /**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */ }
  [type="search"]::-webkit-search-cancel-button, [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
     * Change the border, margin, and padding in all browsers (opinionated).
     */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
  box-sizing: border-box;
  /* 1 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  color: inherit;
  /* 2 */
  white-space: normal;
  /* 1 */ }

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
  overflow: auto; }

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
  display: block; }

/*
     * Add the correct display in all browsers.
     */
summary {
  display: list-item; }

/*
     * Add the correct display in IE 9-.
     */
menu {
  display: block; }

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
  display: inline-block; }

/**
     * Add the correct display in IE.
     */
template {
  display: none; }

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
  display: none; }

.foundation-mq {
  font-family: "small=0em&medium=43.75em&large=64.375em&xlarge=75em&xxlarge=90em"; }

html {
  box-sizing: border-box;
  font-size: 100%; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  margin: 0;
  padding: 0;
  background: #FFF;
  font-family: "Apercu Regular", -apple-system, "system-ui", "Segoe font-family: 'Apercu Regular',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: normal;
  line-height: 1.5;
  color: #4E515E;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic; }

textarea {
  height: auto;
  min-height: 50px;
  border-radius: 0; }

select {
  box-sizing: border-box;
  width: 100%;
  border-radius: 0; }

.map_canvas img,
.map_canvas embed,
.map_canvas object,
.mqa-display img,
.mqa-display embed,
.mqa-display object {
  max-width: none !important; }

button {
  padding: 0;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1; }
  [data-whatinput='mouse'] button {
    outline: 0; }

pre {
  overflow: auto; }

.is-visible {
  display: block !important; }

.is-hidden {
  display: none !important; }

.row {
  max-width: 71.875rem;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-flow: row wrap; }
  .row .row {
    margin-right: -0.625rem;
    margin-left: -0.625rem; }
    @media print, screen and (min-width: 43.75em) {
      .row .row {
        margin-right: -1.75rem;
        margin-left: -1.75rem; } }
    @media print, screen and (min-width: 64.375em) {
      .row .row {
        margin-right: -1.75rem;
        margin-left: -1.75rem; } }
    .row .row.collapse {
      margin-right: 0;
      margin-left: 0; }
  .row.expanded {
    max-width: none; }
  .row:not(.expanded) .row {
    max-width: none; }
  .row.collapse > .column, .row.collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .row.is-collapse-child,
  .row.collapse > .column > .row,
  .row.collapse > .columns > .row {
    margin-right: 0;
    margin-left: 0; }

.column, .columns {
  flex: 1 1 0px;
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  min-width: initial; }
  @media print, screen and (min-width: 43.75em) {
    .column, .columns {
      padding-right: 1.75rem;
      padding-left: 1.75rem; } }

.column.row.row, .row.row.columns {
  float: none;
  display: block; }

.row .column.row.row, .row .row.row.columns {
  margin-right: 0;
  margin-left: 0;
  padding-right: 0;
  padding-left: 0; }

.flex-container {
  display: flex; }

.flex-child-auto {
  flex: 1 1 auto; }

.flex-child-grow {
  flex: 1 0 auto; }

.flex-child-shrink {
  flex: 0 1 auto; }

.flex-dir-row {
  flex-direction: row; }

.flex-dir-row-reverse {
  flex-direction: row-reverse; }

.flex-dir-column {
  flex-direction: column; }

.flex-dir-column-reverse {
  flex-direction: column-reverse; }

.small-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.small-offset-0 {
  margin-left: 0%; }

.small-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.small-offset-1 {
  margin-left: 8.33333%; }

.small-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.small-offset-2 {
  margin-left: 16.66667%; }

.small-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.small-offset-3 {
  margin-left: 25%; }

.small-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.small-offset-4 {
  margin-left: 33.33333%; }

.small-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.small-offset-5 {
  margin-left: 41.66667%; }

.small-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.small-offset-6 {
  margin-left: 50%; }

.small-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.small-offset-7 {
  margin-left: 58.33333%; }

.small-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.small-offset-8 {
  margin-left: 66.66667%; }

.small-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.small-offset-9 {
  margin-left: 75%; }

.small-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.small-offset-10 {
  margin-left: 83.33333%; }

.small-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.small-offset-11 {
  margin-left: 91.66667%; }

.small-order-1 {
  order: 1; }

.small-order-2 {
  order: 2; }

.small-order-3 {
  order: 3; }

.small-order-4 {
  order: 4; }

.small-order-5 {
  order: 5; }

.small-order-6 {
  order: 6; }

.small-up-1 {
  flex-wrap: wrap; }
  .small-up-1 > .column, .small-up-1 > .columns {
    flex: 0 0 100%;
    max-width: 100%; }

.small-up-2 {
  flex-wrap: wrap; }
  .small-up-2 > .column, .small-up-2 > .columns {
    flex: 0 0 50%;
    max-width: 50%; }

.small-up-3 {
  flex-wrap: wrap; }
  .small-up-3 > .column, .small-up-3 > .columns {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }

.small-up-4 {
  flex-wrap: wrap; }
  .small-up-4 > .column, .small-up-4 > .columns {
    flex: 0 0 25%;
    max-width: 25%; }

.small-up-5 {
  flex-wrap: wrap; }
  .small-up-5 > .column, .small-up-5 > .columns {
    flex: 0 0 20%;
    max-width: 20%; }

.small-up-6 {
  flex-wrap: wrap; }
  .small-up-6 > .column, .small-up-6 > .columns {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }

.small-up-7 {
  flex-wrap: wrap; }
  .small-up-7 > .column, .small-up-7 > .columns {
    flex: 0 0 14.28571%;
    max-width: 14.28571%; }

.small-up-8 {
  flex-wrap: wrap; }
  .small-up-8 > .column, .small-up-8 > .columns {
    flex: 0 0 12.5%;
    max-width: 12.5%; }

.small-collapse > .column, .small-collapse > .columns {
  padding-right: 0;
  padding-left: 0; }

.small-uncollapse > .column, .small-uncollapse > .columns {
  padding-right: 0.625rem;
  padding-left: 0.625rem; }

@media print, screen and (min-width: 43.75em) {
  .medium-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .medium-offset-0 {
    margin-left: 0%; }
  .medium-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .medium-offset-1 {
    margin-left: 8.33333%; }
  .medium-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .medium-offset-2 {
    margin-left: 16.66667%; }
  .medium-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .medium-offset-3 {
    margin-left: 25%; }
  .medium-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .medium-offset-4 {
    margin-left: 33.33333%; }
  .medium-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .medium-offset-5 {
    margin-left: 41.66667%; }
  .medium-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .medium-offset-6 {
    margin-left: 50%; }
  .medium-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .medium-offset-7 {
    margin-left: 58.33333%; }
  .medium-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .medium-offset-8 {
    margin-left: 66.66667%; }
  .medium-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .medium-offset-9 {
    margin-left: 75%; }
  .medium-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .medium-offset-10 {
    margin-left: 83.33333%; }
  .medium-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .medium-offset-11 {
    margin-left: 91.66667%; }
  .medium-order-1 {
    order: 1; }
  .medium-order-2 {
    order: 2; }
  .medium-order-3 {
    order: 3; }
  .medium-order-4 {
    order: 4; }
  .medium-order-5 {
    order: 5; }
  .medium-order-6 {
    order: 6; }
  .medium-up-1 {
    flex-wrap: wrap; }
    .medium-up-1 > .column, .medium-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .medium-up-2 {
    flex-wrap: wrap; }
    .medium-up-2 > .column, .medium-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .medium-up-3 {
    flex-wrap: wrap; }
    .medium-up-3 > .column, .medium-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .medium-up-4 {
    flex-wrap: wrap; }
    .medium-up-4 > .column, .medium-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .medium-up-5 {
    flex-wrap: wrap; }
    .medium-up-5 > .column, .medium-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .medium-up-6 {
    flex-wrap: wrap; }
    .medium-up-6 > .column, .medium-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .medium-up-7 {
    flex-wrap: wrap; }
    .medium-up-7 > .column, .medium-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .medium-up-8 {
    flex-wrap: wrap; }
    .medium-up-8 > .column, .medium-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media print, screen and (min-width: 43.75em) and (min-width: 43.75em) {
  .medium-expand {
    flex: 1 1 0px; } }

@media print, screen and (min-width: 43.75em) {
  .medium-flex-dir-row {
    flex-direction: row; }
  .medium-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .medium-flex-dir-column {
    flex-direction: column; }
  .medium-flex-dir-column-reverse {
    flex-direction: column-reverse; }
  .medium-flex-child-auto {
    flex: 1 1 auto; }
  .medium-flex-child-grow {
    flex: 1 0 auto; }
  .medium-flex-child-shrink {
    flex: 0 1 auto; } }

.row.medium-unstack > .column, .row.medium-unstack > .columns {
  flex: 0 0 100%; }
  @media print, screen and (min-width: 43.75em) {
    .row.medium-unstack > .column, .row.medium-unstack > .columns {
      flex: 1 1 0px; } }

@media print, screen and (min-width: 43.75em) {
  .medium-collapse > .column, .medium-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .medium-uncollapse > .column, .medium-uncollapse > .columns {
    padding-right: 1.75rem;
    padding-left: 1.75rem; } }

@media print, screen and (min-width: 64.375em) {
  .large-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .large-offset-0 {
    margin-left: 0%; }
  .large-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .large-offset-1 {
    margin-left: 8.33333%; }
  .large-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .large-offset-2 {
    margin-left: 16.66667%; }
  .large-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .large-offset-3 {
    margin-left: 25%; }
  .large-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .large-offset-4 {
    margin-left: 33.33333%; }
  .large-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .large-offset-5 {
    margin-left: 41.66667%; }
  .large-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .large-offset-6 {
    margin-left: 50%; }
  .large-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .large-offset-7 {
    margin-left: 58.33333%; }
  .large-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .large-offset-8 {
    margin-left: 66.66667%; }
  .large-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .large-offset-9 {
    margin-left: 75%; }
  .large-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .large-offset-10 {
    margin-left: 83.33333%; }
  .large-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .large-offset-11 {
    margin-left: 91.66667%; }
  .large-order-1 {
    order: 1; }
  .large-order-2 {
    order: 2; }
  .large-order-3 {
    order: 3; }
  .large-order-4 {
    order: 4; }
  .large-order-5 {
    order: 5; }
  .large-order-6 {
    order: 6; }
  .large-up-1 {
    flex-wrap: wrap; }
    .large-up-1 > .column, .large-up-1 > .columns {
      flex: 0 0 100%;
      max-width: 100%; }
  .large-up-2 {
    flex-wrap: wrap; }
    .large-up-2 > .column, .large-up-2 > .columns {
      flex: 0 0 50%;
      max-width: 50%; }
  .large-up-3 {
    flex-wrap: wrap; }
    .large-up-3 > .column, .large-up-3 > .columns {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
  .large-up-4 {
    flex-wrap: wrap; }
    .large-up-4 > .column, .large-up-4 > .columns {
      flex: 0 0 25%;
      max-width: 25%; }
  .large-up-5 {
    flex-wrap: wrap; }
    .large-up-5 > .column, .large-up-5 > .columns {
      flex: 0 0 20%;
      max-width: 20%; }
  .large-up-6 {
    flex-wrap: wrap; }
    .large-up-6 > .column, .large-up-6 > .columns {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
  .large-up-7 {
    flex-wrap: wrap; }
    .large-up-7 > .column, .large-up-7 > .columns {
      flex: 0 0 14.28571%;
      max-width: 14.28571%; }
  .large-up-8 {
    flex-wrap: wrap; }
    .large-up-8 > .column, .large-up-8 > .columns {
      flex: 0 0 12.5%;
      max-width: 12.5%; } }

@media print, screen and (min-width: 64.375em) and (min-width: 64.375em) {
  .large-expand {
    flex: 1 1 0px; } }

@media print, screen and (min-width: 64.375em) {
  .large-flex-dir-row {
    flex-direction: row; }
  .large-flex-dir-row-reverse {
    flex-direction: row-reverse; }
  .large-flex-dir-column {
    flex-direction: column; }
  .large-flex-dir-column-reverse {
    flex-direction: column-reverse; }
  .large-flex-child-auto {
    flex: 1 1 auto; }
  .large-flex-child-grow {
    flex: 1 0 auto; }
  .large-flex-child-shrink {
    flex: 0 1 auto; } }

.row.large-unstack > .column, .row.large-unstack > .columns {
  flex: 0 0 100%; }
  @media print, screen and (min-width: 64.375em) {
    .row.large-unstack > .column, .row.large-unstack > .columns {
      flex: 1 1 0px; } }

@media print, screen and (min-width: 64.375em) {
  .large-collapse > .column, .large-collapse > .columns {
    padding-right: 0;
    padding-left: 0; }
  .large-uncollapse > .column, .large-uncollapse > .columns {
    padding-right: 1.75rem;
    padding-left: 1.75rem; } }

.shrink {
  flex: 0 0 auto;
  max-width: 100%; }

.column-block {
  margin-bottom: 1.25rem; }
  .column-block > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 43.75em) {
    .column-block {
      margin-bottom: 3.5rem; }
      .column-block > :last-child {
        margin-bottom: 0; } }

div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

p {
  margin-bottom: 1rem;
  font-size: inherit;
  line-height: 1.6;
  text-rendering: optimizeLegibility; }

em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 80%;
  line-height: inherit; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Apercu Regular", -apple-system, "system-ui", "Segoe font-family: 'Apercu Regular',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: normal;
  color: inherit;
  text-rendering: optimizeLegibility; }
  h1 small,
  h2 small,
  h3 small,
  h4 small,
  h5 small,
  h6 small {
    line-height: 0;
    color: #cacaca; }

h1 {
  font-size: 2rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h2 {
  font-size: 1.75rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h3 {
  font-size: 1.375rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h4 {
  font-size: 1.25rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h5 {
  font-size: 1.125rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

h6 {
  font-size: 1rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 0.5rem; }

@media print, screen and (min-width: 43.75em) {
  h1 {
    font-size: 2.875rem; }
  h2 {
    font-size: 2.375rem; }
  h3 {
    font-size: 1.75rem; }
  h4 {
    font-size: 1.5rem; }
  h5 {
    font-size: 1.125rem; }
  h6 {
    font-size: 1.125rem; } }

a {
  line-height: inherit;
  color: #6B7C93;
  text-decoration: none;
  cursor: pointer; }
  a:hover, a:focus {
    color: #1F8CEB;
    text-decoration: underline; }
  a img {
    border: 0; }

hr {
  clear: both;
  max-width: 71.875rem;
  height: 0;
  margin: 1.25rem auto;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #cacaca;
  border-left: 0; }

ul,
ol,
dl {
  margin-bottom: 1rem;
  list-style-position: outside;
  line-height: 1.6; }

li {
  font-size: inherit; }

ul {
  margin-left: 1.25rem;
  list-style-type: disc; }

ol {
  margin-left: 1.25rem; }

ul ul, ol ul, ul ol, ol ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }

dl {
  margin-bottom: 1rem; }
  dl dt {
    margin-bottom: 0.3rem;
    font-weight: bold; }

blockquote {
  margin: 0 0 1rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #cacaca; }
  blockquote, blockquote p {
    line-height: 1.6;
    color: #8a8a8a; }

cite {
  display: block;
  font-size: 0.8125rem;
  color: #8a8a8a; }
  cite:before {
    content: "— "; }

abbr {
  border-bottom: 1px dotted #000;
  color: #4E515E;
  cursor: help; }

figure {
  margin: 0; }

code {
  padding: 0.125rem 0.3125rem 0.0625rem;
  border: 1px solid #cacaca;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #000; }

kbd {
  margin: 0;
  padding: 0.125rem 0.25rem 0;
  background-color: #e6e6e6;
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  color: #000; }

.subheader {
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  font-weight: normal;
  line-height: 1.5;
  color: #8a8a8a; }

.lead {
  font-size: 150%;
  line-height: 1.5; }

.stat {
  font-size: 2.5rem;
  line-height: 1; }
  p + .stat {
    margin-top: -1rem; }

.no-bullet {
  margin-left: 0;
  list-style: none; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-justify {
  text-align: justify; }

@media print, screen and (min-width: 43.75em) {
  .medium-text-left {
    text-align: left; }
  .medium-text-right {
    text-align: right; }
  .medium-text-center {
    text-align: center; }
  .medium-text-justify {
    text-align: justify; } }

@media print, screen and (min-width: 64.375em) {
  .large-text-left {
    text-align: left; }
  .large-text-right {
    text-align: right; }
  .large-text-center {
    text-align: center; }
  .large-text-justify {
    text-align: justify; } }

.show-for-print {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    box-shadow: none !important;
    color: black !important;
    text-shadow: none !important; }
  .show-for-print {
    display: block !important; }
  .hide-for-print {
    display: none !important; }
  table.show-for-print {
    display: table !important; }
  thead.show-for-print {
    display: table-header-group !important; }
  tbody.show-for-print {
    display: table-row-group !important; }
  tr.show-for-print {
    display: table-row !important; }
  td.show-for-print {
    display: table-cell !important; }
  th.show-for-print {
    display: table-cell !important; }
  a,
  a:visited {
    text-decoration: underline; }
  a[href]:after {
    content: " (" attr(href) ")"; }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: ''; }
  abbr[title]:after {
    content: " (" attr(title) ")"; }
  pre,
  blockquote {
    border: 1px solid #8a8a8a;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 0.5cm; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; } }

.button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  padding: 14px 18px;
  -webkit-appearance: none;
  border: 1px solid transparent;
  border-radius: 4px;
  transition: background-color 0.25s ease-out, color 0.25s ease-out;
  font-size: 0.9rem;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  background-color: #cc7565;
  color: #fefefe; }
  [data-whatinput='mouse'] .button {
    outline: 0; }
  .button:hover, .button:focus {
    background-color: #c15643;
    color: #fefefe; }
  .button.tiny {
    font-size: 0.6rem; }
  .button.small {
    font-size: 0.8rem; }
  .button.large {
    font-size: 1.25rem; }
  .button.expanded {
    display: block;
    width: 100%;
    margin-right: 0;
    margin-left: 0; }
  .button.primary {
    background-color: #cc7565;
    color: #000; }
    .button.primary:hover, .button.primary:focus {
      background-color: #b7503d;
      color: #000; }
  .button.secondary {
    background-color: #767676;
    color: #000; }
    .button.secondary:hover, .button.secondary:focus {
      background-color: #5e5e5e;
      color: #000; }
  .button.success {
    background-color: #3adb76;
    color: #000; }
    .button.success:hover, .button.success:focus {
      background-color: #22bb5b;
      color: #000; }
  .button.warning {
    background-color: #ffae00;
    color: #000; }
    .button.warning:hover, .button.warning:focus {
      background-color: #cc8b00;
      color: #000; }
  .button.alert {
    background-color: #cc4b37;
    color: #000; }
    .button.alert:hover, .button.alert:focus {
      background-color: #a53b2a;
      color: #000; }
  .button.hollow {
    border: 1px solid #cc7565;
    color: #cc7565; }
    .button.hollow, .button.hollow:hover, .button.hollow:focus {
      background-color: transparent; }
    .button.hollow:hover, .button.hollow:focus {
      border-color: #733226;
      color: #733226; }
    .button.hollow.primary {
      border: 1px solid #cc7565;
      color: #cc7565; }
      .button.hollow.primary:hover, .button.hollow.primary:focus {
        border-color: #733226;
        color: #733226; }
    .button.hollow.secondary {
      border: 1px solid #767676;
      color: #767676; }
      .button.hollow.secondary:hover, .button.hollow.secondary:focus {
        border-color: #3b3b3b;
        color: #3b3b3b; }
    .button.hollow.success {
      border: 1px solid #3adb76;
      color: #3adb76; }
      .button.hollow.success:hover, .button.hollow.success:focus {
        border-color: #157539;
        color: #157539; }
    .button.hollow.warning {
      border: 1px solid #ffae00;
      color: #ffae00; }
      .button.hollow.warning:hover, .button.hollow.warning:focus {
        border-color: #805700;
        color: #805700; }
    .button.hollow.alert {
      border: 1px solid #cc4b37;
      color: #cc4b37; }
      .button.hollow.alert:hover, .button.hollow.alert:focus {
        border-color: #67251a;
        color: #67251a; }
  .button.disabled, .button[disabled] {
    opacity: 0.25;
    cursor: not-allowed; }
    .button.disabled, .button.disabled:hover, .button.disabled:focus, .button[disabled], .button[disabled]:hover, .button[disabled]:focus {
      background-color: #cc7565;
      color: #fefefe; }
    .button.disabled.primary, .button[disabled].primary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.primary, .button.disabled.primary:hover, .button.disabled.primary:focus, .button[disabled].primary, .button[disabled].primary:hover, .button[disabled].primary:focus {
        background-color: #cc7565;
        color: #000; }
    .button.disabled.secondary, .button[disabled].secondary {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.secondary, .button.disabled.secondary:hover, .button.disabled.secondary:focus, .button[disabled].secondary, .button[disabled].secondary:hover, .button[disabled].secondary:focus {
        background-color: #767676;
        color: #000; }
    .button.disabled.success, .button[disabled].success {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.success, .button.disabled.success:hover, .button.disabled.success:focus, .button[disabled].success, .button[disabled].success:hover, .button[disabled].success:focus {
        background-color: #3adb76;
        color: #000; }
    .button.disabled.warning, .button[disabled].warning {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.warning, .button.disabled.warning:hover, .button.disabled.warning:focus, .button[disabled].warning, .button[disabled].warning:hover, .button[disabled].warning:focus {
        background-color: #ffae00;
        color: #000; }
    .button.disabled.alert, .button[disabled].alert {
      opacity: 0.25;
      cursor: not-allowed; }
      .button.disabled.alert, .button.disabled.alert:hover, .button.disabled.alert:focus, .button[disabled].alert, .button[disabled].alert:hover, .button[disabled].alert:focus {
        background-color: #cc4b37;
        color: #000; }
  .button.dropdown::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.4em;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #fefefe transparent transparent;
    position: relative;
    top: 0.4em;
    display: inline-block;
    float: right;
    margin-left: 18px; }
  .button.arrow-only::after {
    top: -0.1em;
    float: none;
    margin-left: 0; }

[type='text'], [type='password'], [type='date'], [type='datetime'], [type='datetime-local'], [type='month'], [type='week'], [type='email'], [type='number'], [type='search'], [type='tel'], [type='time'], [type='url'], [type='color'],
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  font-family: inherit;
  font-size: 1rem;
  font-weight: normal;
  color: #000;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out;
  appearance: none; }
  [type='text']:focus, [type='password']:focus, [type='date']:focus, [type='datetime']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='week']:focus, [type='email']:focus, [type='number']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='url']:focus, [type='color']:focus,
  textarea:focus {
    outline: none;
    border: 1px solid #8a8a8a;
    background-color: #fefefe;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }

textarea {
  max-width: 100%; }
  textarea[rows] {
    height: auto; }

input::placeholder,
textarea::placeholder {
  color: #cacaca; }

input:disabled, input[readonly],
textarea:disabled,
textarea[readonly] {
  background-color: #e6e6e6;
  cursor: not-allowed; }

[type='submit'],
[type='button'] {
  appearance: none;
  border-radius: 0; }

input[type='search'] {
  box-sizing: border-box; }

[type='file'],
[type='checkbox'],
[type='radio'] {
  margin: 0 0 1rem; }

[type='checkbox'] + label,
[type='radio'] + label {
  display: inline-block;
  vertical-align: baseline;
  margin-left: 0.5rem;
  margin-right: 1rem;
  margin-bottom: 0; }
  [type='checkbox'] + label[for],
  [type='radio'] + label[for] {
    cursor: pointer; }

label > [type='checkbox'],
label > [type='radio'] {
  margin-right: 0.5rem; }

[type='file'] {
  width: 100%; }

label {
  display: block;
  margin: 0;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.8;
  color: #000; }
  label.middle {
    margin: 0 0 1rem;
    padding: 0.5625rem 0; }

.help-text {
  margin-top: -0.5rem;
  font-size: 0.8125rem;
  font-style: italic;
  color: #000; }

.input-group {
  display: table;
  width: 100%;
  margin-bottom: 1rem; }
  .input-group > :first-child {
    border-radius: 0 0 0 0; }
  .input-group > :last-child > * {
    border-radius: 0 0 0 0; }

.input-group-label, .input-group-field, .input-group-button, .input-group-button a,
.input-group-button input,
.input-group-button button,
.input-group-button label {
  margin: 0;
  white-space: nowrap;
  display: table-cell;
  vertical-align: middle; }

.input-group-label {
  padding: 0 1rem;
  border: 1px solid #cacaca;
  background: #e6e6e6;
  color: #000;
  text-align: center;
  white-space: nowrap;
  width: 1%;
  height: 100%; }
  .input-group-label:first-child {
    border-right: 0; }
  .input-group-label:last-child {
    border-left: 0; }

.input-group-field {
  border-radius: 0;
  height: 2.5rem; }

.input-group-button {
  padding-top: 0;
  padding-bottom: 0;
  text-align: center;
  width: 1%;
  height: 100%; }
  .input-group-button a,
  .input-group-button input,
  .input-group-button button,
  .input-group-button label {
    height: 2.5rem;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 1rem; }

.input-group .input-group-button {
  display: table-cell; }

fieldset {
  margin: 0;
  padding: 0;
  border: 0; }

legend {
  max-width: 100%;
  margin-bottom: 0.5rem; }

.fieldset {
  margin: 1.125rem 0;
  padding: 1.25rem;
  border: 1px solid #cacaca; }
  .fieldset legend {
    margin: 0;
    margin-left: -0.1875rem;
    padding: 0 0.1875rem;
    background: #FFF; }

select {
  height: 2.4375rem;
  margin: 0 0 1rem;
  padding: 0.5rem;
  appearance: none;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  font-family: inherit;
  font-size: 1rem;
  line-height: normal;
  color: #000;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  padding-right: 1.5rem;
  transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  @media screen and (min-width: 0\0) {
    select {
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAYCAYAAACbU/80AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIpJREFUeNrEkckNgDAMBBfRkEt0ObRBBdsGXUDgmQfK4XhH2m8czQAAy27R3tsw4Qfe2x8uOO6oYLb6GlOor3GF+swURAOmUJ+RwtEJs9WvTGEYxBXqI1MQAZhCfUQKRzDMVj+TwrAIV6jvSUEkYAr1LSkcyTBb/V+KYfX7xAeusq3sLDtGH3kEGACPWIflNZfhRQAAAABJRU5ErkJggg=="); } }
  select:focus {
    outline: none;
    border: 1px solid #8a8a8a;
    background-color: #fefefe;
    box-shadow: 0 0 5px #cacaca;
    transition: box-shadow 0.5s, border-color 0.25s ease-in-out; }
  select:disabled {
    background-color: #e6e6e6;
    cursor: not-allowed; }
  select::-ms-expand {
    display: none; }
  select[multiple] {
    height: auto;
    background-image: none; }

.is-invalid-input:not(:focus) {
  border-color: #cc4b37;
  background-color: #f9ecea; }
  .is-invalid-input:not(:focus)::placeholder {
    color: #cc4b37; }

.is-invalid-label {
  color: #cc4b37; }

.form-error {
  display: none;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  font-size: 0.75rem;
  font-weight: bold;
  color: #cc4b37; }
  .form-error.is-visible {
    display: block; }

input[type="range"] {
  display: block;
  width: 100%;
  height: auto;
  margin-top: 0.45rem;
  margin-bottom: 0.45rem;
  appearance: none;
  border: 0;
  line-height: 1;
  cursor: pointer; }
  input[type="range"]:focus {
    outline: 0; }
  input[type="range"][disabled] {
    opacity: 0.25; }
  input[type="range"]::-webkit-slider-runnable-track {
    height: 0.5rem;
    background: #e6e6e6; }
  input[type="range"]::-webkit-slider-handle {
    width: 1.4rem;
    height: 1.4rem;
    margin-top: -0.45rem;
    -webkit-appearance: none;
    background: #cc7565; }
  input[type="range"]::-moz-range-track {
    height: 0.5rem;
    -moz-appearance: none;
    background: #e6e6e6; }
  input[type="range"]::-moz-range-thumb {
    width: 1.4rem;
    height: 1.4rem;
    margin-top: -0.45rem;
    -moz-appearance: none;
    background: #cc7565; }
  input[type="range"]::-ms-track {
    height: 0.5rem;
    border: 0;
    border-top: 0.45rem solid #FFF;
    border-bottom: 0.45rem solid #FFF;
    background: #e6e6e6;
    overflow: visible;
    color: transparent; }
  input[type="range"]::-ms-thumb {
    width: 1.4rem;
    height: 1.4rem;
    border: 0;
    background: #cc7565; }
  input[type="range"]::-ms-fill-lower {
    background: #cacaca; }
  input[type="range"]::-ms-fill-upper {
    background: #e6e6e6; }
  output {
    vertical-align: middle;
    margin-left: 0.5em;
    line-height: 1.4rem; }

.accordion {
  margin-left: 0;
  background: #fefefe;
  list-style-type: none; }

.accordion-item:first-child > :first-child {
  border-radius: 0 0 0 0; }

.accordion-item:last-child > :last-child {
  border-radius: 0 0 0 0; }

.accordion-title {
  position: relative;
  display: block;
  padding: 1.5rem 3rem;
  border: 1px solid #c7c7ce;
  border-bottom: 0;
  font-size: 1rem;
  line-height: 1;
  color: #cc7565; }
  :last-child:not(.is-active) > .accordion-title {
    border-bottom: 1px solid #c7c7ce;
    border-radius: 0 0 0 0; }
  .accordion-title:hover, .accordion-title:focus {
    background-color: #F7F7FA; }
  .accordion-title::before {
    position: absolute;
    top: 50%;
    right: 1rem;
    margin-top: -0.5rem;
    content: '+'; }
  .is-active > .accordion-title::before {
    content: '\2013'; }

.accordion-content {
  display: none;
  padding: 0rem 3rem 1.5rem 3rem;
  border: 1px solid #c7c7ce;
  border-bottom: 0;
  background-color: #fefefe;
  color: #4E515E; }
  :last-child > .accordion-content:last-child {
    border-bottom: 1px solid #c7c7ce; }

.is-accordion-submenu-parent > a {
  position: relative; }
  .is-accordion-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 10px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #444B73 transparent transparent;
    position: absolute;
    top: 50%;
    margin-top: -5px;
    right: 1rem; }

.is-accordion-submenu-parent[aria-expanded='true'] > a::after {
  transform: rotate(180deg);
  transform-origin: 50% 50%; }

.badge {
  display: inline-block;
  min-width: 2.1em;
  padding: 0.3em;
  border-radius: 50%;
  font-size: 0.6rem;
  text-align: center;
  background: #cc7565;
  color: #fefefe; }
  .badge.primary {
    background: #cc7565;
    color: #000; }
  .badge.secondary {
    background: #767676;
    color: #000; }
  .badge.success {
    background: #3adb76;
    color: #000; }
  .badge.warning {
    background: #ffae00;
    color: #000; }
  .badge.alert {
    background: #cc4b37;
    color: #000; }

.breadcrumbs {
  margin: 0 0 1rem 0;
  list-style: none; }
  .breadcrumbs::before, .breadcrumbs::after {
    display: table;
    content: ' '; }
  .breadcrumbs::after {
    clear: both; }
  .breadcrumbs li {
    float: left;
    font-size: 0.6875rem;
    color: #000;
    cursor: default;
    text-transform: uppercase; }
    .breadcrumbs li:not(:last-child)::after {
      position: relative;
      top: 1px;
      margin: 0 0.75rem;
      opacity: 1;
      content: "/";
      color: #cacaca; }
  .breadcrumbs a {
    color: #cc7565; }
    .breadcrumbs a:hover {
      text-decoration: underline; }
  .breadcrumbs .disabled {
    color: #cacaca;
    cursor: not-allowed; }

.button-group {
  margin-bottom: 1rem;
  font-size: 0; }
  .button-group::before, .button-group::after {
    display: table;
    content: ' '; }
  .button-group::after {
    clear: both; }
  .button-group .button {
    margin: 0;
    margin-right: 1px;
    margin-bottom: 1px;
    font-size: 0.9rem; }
    .button-group .button:last-child {
      margin-right: 0; }
  .button-group.tiny .button {
    font-size: 0.6rem; }
  .button-group.small .button {
    font-size: 0.8rem; }
  .button-group.large .button {
    font-size: 1.25rem; }
  .button-group.expanded {
    margin-right: -1px; }
    .button-group.expanded::before, .button-group.expanded::after {
      display: none; }
    .button-group.expanded .button:first-child:last-child {
      width: 100%; }
    .button-group.expanded .button:first-child:nth-last-child(2), .button-group.expanded .button:first-child:nth-last-child(2):first-child:nth-last-child(2) ~ .button {
      display: inline-block;
      width: calc(50% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(2):last-child, .button-group.expanded .button:first-child:nth-last-child(2):first-child:nth-last-child(2) ~ .button:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(3), .button-group.expanded .button:first-child:nth-last-child(3):first-child:nth-last-child(3) ~ .button {
      display: inline-block;
      width: calc(33.33333% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(3):last-child, .button-group.expanded .button:first-child:nth-last-child(3):first-child:nth-last-child(3) ~ .button:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(4), .button-group.expanded .button:first-child:nth-last-child(4):first-child:nth-last-child(4) ~ .button {
      display: inline-block;
      width: calc(25% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(4):last-child, .button-group.expanded .button:first-child:nth-last-child(4):first-child:nth-last-child(4) ~ .button:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(5), .button-group.expanded .button:first-child:nth-last-child(5):first-child:nth-last-child(5) ~ .button {
      display: inline-block;
      width: calc(20% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(5):last-child, .button-group.expanded .button:first-child:nth-last-child(5):first-child:nth-last-child(5) ~ .button:last-child {
        margin-right: -6px; }
    .button-group.expanded .button:first-child:nth-last-child(6), .button-group.expanded .button:first-child:nth-last-child(6):first-child:nth-last-child(6) ~ .button {
      display: inline-block;
      width: calc(16.66667% - 1px);
      margin-right: 1px; }
      .button-group.expanded .button:first-child:nth-last-child(6):last-child, .button-group.expanded .button:first-child:nth-last-child(6):first-child:nth-last-child(6) ~ .button:last-child {
        margin-right: -6px; }
  .button-group.primary .button {
    background-color: #cc7565;
    color: #000; }
    .button-group.primary .button:hover, .button-group.primary .button:focus {
      background-color: #b7503d;
      color: #000; }
  .button-group.secondary .button {
    background-color: #767676;
    color: #000; }
    .button-group.secondary .button:hover, .button-group.secondary .button:focus {
      background-color: #5e5e5e;
      color: #000; }
  .button-group.success .button {
    background-color: #3adb76;
    color: #000; }
    .button-group.success .button:hover, .button-group.success .button:focus {
      background-color: #22bb5b;
      color: #000; }
  .button-group.warning .button {
    background-color: #ffae00;
    color: #000; }
    .button-group.warning .button:hover, .button-group.warning .button:focus {
      background-color: #cc8b00;
      color: #000; }
  .button-group.alert .button {
    background-color: #cc4b37;
    color: #000; }
    .button-group.alert .button:hover, .button-group.alert .button:focus {
      background-color: #a53b2a;
      color: #000; }
  .button-group.stacked .button, .button-group.stacked-for-small .button, .button-group.stacked-for-medium .button {
    width: 100%; }
    .button-group.stacked .button:last-child, .button-group.stacked-for-small .button:last-child, .button-group.stacked-for-medium .button:last-child {
      margin-bottom: 0; }
  @media print, screen and (min-width: 43.75em) {
    .button-group.stacked-for-small .button {
      width: auto;
      margin-bottom: 0; } }
  @media print, screen and (min-width: 64.375em) {
    .button-group.stacked-for-medium .button {
      width: auto;
      margin-bottom: 0; } }
  @media screen and (max-width: 43.6875em) {
    .button-group.stacked-for-small.expanded {
      display: block; }
      .button-group.stacked-for-small.expanded .button {
        display: block;
        margin-right: 0; } }

.callout {
  position: relative;
  margin: 0 0 1rem 0;
  padding: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 0;
  background-color: white;
  color: #4E515E; }
  .callout > :first-child {
    margin-top: 0; }
  .callout > :last-child {
    margin-bottom: 0; }
  .callout.primary {
    background-color: #f7eae8;
    color: #4E515E; }
  .callout.secondary {
    background-color: #eaeaea;
    color: #4E515E; }
  .callout.success {
    background-color: #e1faea;
    color: #4E515E; }
  .callout.warning {
    background-color: #fff3d9;
    color: #4E515E; }
  .callout.alert {
    background-color: #f7e4e1;
    color: #4E515E; }
  .callout.small {
    padding-top: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 0.5rem; }
  .callout.large {
    padding-top: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
    padding-left: 3rem; }

.card {
  margin-bottom: 1rem;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  background: #fefefe;
  box-shadow: 0px 2px 5px #dddddd;
  overflow: hidden;
  color: #4E515E; }
  .card > :last-child {
    margin-bottom: 0; }

.card-divider {
  padding: 1rem;
  background: #e6e6e6; }
  .card-divider > :last-child {
    margin-bottom: 0; }

.card-section {
  padding: 1rem; }
  .card-section > :last-child {
    margin-bottom: 0; }

.close-button {
  position: absolute;
  color: #8a8a8a;
  cursor: pointer; }
  [data-whatinput='mouse'] .close-button {
    outline: 0; }
  .close-button:hover, .close-button:focus {
    color: #000; }
  .close-button.small {
    right: 0.66rem;
    top: 0.33em;
    font-size: 1.5em;
    line-height: 1; }
  .close-button, .close-button.medium {
    right: 1rem;
    top: 0.5rem;
    font-size: 2em;
    line-height: 1; }

.menu {
  margin: 0;
  list-style-type: none; }
  .menu > li {
    display: table-cell;
    vertical-align: middle; }
    [data-whatinput='mouse'] .menu > li {
      outline: 0; }
  .menu > li > a {
    display: block;
    padding: 0.7rem 1rem;
    line-height: 1; }
  .menu input,
  .menu select,
  .menu a,
  .menu button {
    margin-bottom: 0; }
  .menu > li > a img,
  .menu > li > a i,
  .menu > li > a svg {
    vertical-align: middle; }
    .menu > li > a img + span,
    .menu > li > a i + span,
    .menu > li > a svg + span {
      vertical-align: middle; }
  .menu > li > a img,
  .menu > li > a i,
  .menu > li > a svg {
    margin-right: 0.25rem;
    display: inline-block; }
  .menu > li, .menu.horizontal > li {
    display: table-cell; }
  .menu.expanded {
    display: table;
    width: 100%;
    table-layout: fixed; }
    .menu.expanded > li:first-child:last-child {
      width: 100%; }
  .menu.vertical > li {
    display: block; }
  @media print, screen and (min-width: 43.75em) {
    .menu.medium-horizontal > li {
      display: table-cell; }
    .menu.medium-expanded {
      display: table;
      width: 100%;
      table-layout: fixed; }
      .menu.medium-expanded > li:first-child:last-child {
        width: 100%; }
    .menu.medium-vertical > li {
      display: block; } }
  @media print, screen and (min-width: 64.375em) {
    .menu.large-horizontal > li {
      display: table-cell; }
    .menu.large-expanded {
      display: table;
      width: 100%;
      table-layout: fixed; }
      .menu.large-expanded > li:first-child:last-child {
        width: 100%; }
    .menu.large-vertical > li {
      display: block; } }
  .menu.simple li {
    display: inline-block;
    vertical-align: top;
    line-height: 1; }
  .menu.simple a {
    padding: 0; }
  .menu.simple li {
    margin-left: 0;
    margin-right: 1rem; }
  .menu.simple.align-right li {
    margin-right: 0;
    margin-left: 1rem; }
  .menu.align-right::before, .menu.align-right::after {
    display: table;
    content: ' '; }
  .menu.align-right::after {
    clear: both; }
  .menu.align-right > li {
    float: right; }
  .menu.icon-top > li > a {
    text-align: center; }
    .menu.icon-top > li > a img,
    .menu.icon-top > li > a i,
    .menu.icon-top > li > a svg {
      display: block;
      margin: 0 auto 0.25rem; }
  .menu.icon-top.vertical a > span {
    margin: auto; }
  .menu.nested {
    margin-left: 1rem; }
  .menu .active > a {
    background: #cc7565;
    color: #fefefe; }
  .menu.menu-bordered li {
    border: 1px solid #e6e6e6; }
    .menu.menu-bordered li:not(:first-child) {
      border-top: 0; }
  .menu.menu-hover li:hover {
    background-color: #e6e6e6; }

.menu-text {
  padding-top: 0;
  padding-bottom: 0;
  padding: 0.7rem 1rem;
  font-weight: bold;
  line-height: 1;
  color: inherit; }

.menu-centered {
  text-align: center; }
  .menu-centered > .menu {
    display: inline-block;
    vertical-align: top; }

.no-js [data-responsive-menu] ul {
  display: none; }

.menu-icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #fefefe;
    box-shadow: 0 7px 0 #fefefe, 0 14px 0 #fefefe;
    content: ''; }
  .menu-icon:hover::after {
    background: #cacaca;
    box-shadow: 0 7px 0 #cacaca, 0 14px 0 #cacaca; }

.menu-icon.dark {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 16px;
  cursor: pointer; }
  .menu-icon.dark::after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background: #000;
    box-shadow: 0 7px 0 #000, 0 14px 0 #000;
    content: ''; }
  .menu-icon.dark:hover::after {
    background: #8a8a8a;
    box-shadow: 0 7px 0 #8a8a8a, 0 14px 0 #8a8a8a; }

.is-drilldown {
  position: relative;
  overflow: hidden; }
  .is-drilldown li {
    display: block; }
  .is-drilldown.animate-height {
    transition: height 0.5s; }

.is-drilldown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: -1;
  width: 100%;
  background: #fefefe;
  transition: transform 0.15s linear; }
  .is-drilldown-submenu.is-active {
    z-index: 1;
    display: block;
    transform: translateX(-100%); }
  .is-drilldown-submenu.is-closing {
    transform: translateX(100%); }

.drilldown-submenu-cover-previous {
  min-height: 100%; }

.is-drilldown-submenu-parent > a {
  position: relative; }
  .is-drilldown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #cc7565;
    position: absolute;
    top: 50%;
    margin-top: -6px;
    right: 1rem; }

.js-drilldown-back > a::before {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #cc7565 transparent transparent;
  border-left-width: 0;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.75rem;
  border-left-width: 0; }

.dropdown-pane {
  position: absolute;
  z-index: 10;
  display: block;
  width: 300px;
  padding: 1rem;
  visibility: hidden;
  border: 1px solid #4E515E;
  border-radius: 0;
  background-color: #FFF;
  font-size: 1rem; }
  .dropdown-pane.is-open {
    visibility: visible; }

.dropdown-pane.tiny {
  width: 100px; }

.dropdown-pane.small {
  width: 200px; }

.dropdown-pane.large {
  width: 400px; }

.dropdown.menu > li.opens-left > .is-dropdown-submenu {
  top: 100%;
  right: 0;
  left: auto; }

.dropdown.menu > li.opens-right > .is-dropdown-submenu {
  top: 100%;
  right: auto;
  left: 0; }

.dropdown.menu > li.is-dropdown-submenu-parent > a {
  position: relative;
  padding-right: 1.5rem; }

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-bottom-width: 0;
  border-top-style: solid;
  border-color: #6B7C93 transparent transparent;
  right: 5px;
  margin-top: -3px; }

[data-whatinput='mouse'] .dropdown.menu a {
  outline: 0; }

.no-js .dropdown.menu ul {
  display: none; }

.dropdown.menu.vertical > li .is-dropdown-submenu {
  top: 0; }

.dropdown.menu.vertical > li.opens-left > .is-dropdown-submenu {
  right: 100%;
  left: auto; }

.dropdown.menu.vertical > li.opens-right > .is-dropdown-submenu {
  right: auto;
  left: 100%; }

.dropdown.menu.vertical > li > a::after {
  right: 14px; }

.dropdown.menu.vertical > li.opens-left > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-left-width: 0;
  border-right-style: solid;
  border-color: transparent #6B7C93 transparent transparent; }

.dropdown.menu.vertical > li.opens-right > a::after {
  display: block;
  width: 0;
  height: 0;
  border: inset 6px;
  content: '';
  border-right-width: 0;
  border-left-style: solid;
  border-color: transparent transparent transparent #6B7C93; }

@media print, screen and (min-width: 43.75em) {
  .dropdown.menu.medium-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.medium-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.medium-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #6B7C93 transparent transparent;
    right: 5px;
    margin-top: -3px; }
  .dropdown.menu.medium-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.medium-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .dropdown.menu.medium-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.medium-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.medium-vertical > li.opens-left > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #6B7C93 transparent transparent; }
  .dropdown.menu.medium-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #6B7C93; } }

@media print, screen and (min-width: 64.375em) {
  .dropdown.menu.large-horizontal > li.opens-left > .is-dropdown-submenu {
    top: 100%;
    right: 0;
    left: auto; }
  .dropdown.menu.large-horizontal > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a {
    position: relative;
    padding-right: 1.5rem; }
  .dropdown.menu.large-horizontal > li.is-dropdown-submenu-parent > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #6B7C93 transparent transparent;
    right: 5px;
    margin-top: -3px; }
  .dropdown.menu.large-vertical > li .is-dropdown-submenu {
    top: 0; }
  .dropdown.menu.large-vertical > li.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .dropdown.menu.large-vertical > li.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }
  .dropdown.menu.large-vertical > li > a::after {
    right: 14px; }
  .dropdown.menu.large-vertical > li.opens-left > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #6B7C93 transparent transparent; }
  .dropdown.menu.large-vertical > li.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #6B7C93; } }

.dropdown.menu.align-right .is-dropdown-submenu.first-sub {
  top: 100%;
  right: 0;
  left: auto; }

.is-dropdown-menu.vertical {
  width: 100px; }
  .is-dropdown-menu.vertical.align-right {
    float: right; }

.is-dropdown-submenu-parent {
  position: relative; }
  .is-dropdown-submenu-parent a::after {
    position: absolute;
    top: 50%;
    right: 5px;
    margin-top: -6px; }
  .is-dropdown-submenu-parent.opens-inner > .is-dropdown-submenu {
    top: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-left > .is-dropdown-submenu {
    right: 100%;
    left: auto; }
  .is-dropdown-submenu-parent.opens-right > .is-dropdown-submenu {
    right: auto;
    left: 100%; }

.is-dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  z-index: 1;
  display: none;
  min-width: 200px;
  border: 1px solid #cacaca;
  background: #fefefe; }
  .is-dropdown-submenu .is-dropdown-submenu-parent > a::after {
    right: 14px; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-left > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #6B7C93 transparent transparent; }
  .is-dropdown-submenu .is-dropdown-submenu-parent.opens-right > a::after {
    display: block;
    width: 0;
    height: 0;
    border: inset 6px;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #6B7C93; }
  .is-dropdown-submenu .is-dropdown-submenu {
    margin-top: -1px; }
  .is-dropdown-submenu > li {
    width: 100%; }
  .is-dropdown-submenu.js-dropdown-active {
    display: block; }

.responsive-embed,
.flex-video {
  position: relative;
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: 75%;
  overflow: hidden; }
  .responsive-embed iframe,
  .responsive-embed object,
  .responsive-embed embed,
  .responsive-embed video,
  .flex-video iframe,
  .flex-video object,
  .flex-video embed,
  .flex-video video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .responsive-embed.widescreen,
  .flex-video.widescreen {
    padding-bottom: 56.25%; }

.label {
  display: inline-block;
  padding: 0.33333rem 0.5rem;
  border-radius: 0;
  font-size: 0.75rem;
  line-height: 1;
  white-space: nowrap;
  cursor: default;
  background: #cc7565;
  color: #fefefe; }
  .label.primary {
    background: #cc7565;
    color: #000; }
  .label.secondary {
    background: #767676;
    color: #000; }
  .label.success {
    background: #3adb76;
    color: #000; }
  .label.warning {
    background: #ffae00;
    color: #000; }
  .label.alert {
    background: #cc4b37;
    color: #000; }

.media-object {
  display: block;
  margin-bottom: 1rem; }
  .media-object img {
    max-width: none; }
  @media screen and (max-width: 43.6875em) {
    .media-object.stack-for-small .media-object-section {
      padding: 0;
      padding-bottom: 1rem;
      display: block; }
      .media-object.stack-for-small .media-object-section img {
        width: 100%; } }

.media-object-section {
  display: table-cell;
  vertical-align: top; }
  .media-object-section:first-child {
    padding-right: 1rem; }
  .media-object-section:last-child:not(:nth-child(2)) {
    padding-left: 1rem; }
  .media-object-section > :last-child {
    margin-bottom: 0; }
  .media-object-section.middle {
    vertical-align: middle; }
  .media-object-section.bottom {
    vertical-align: bottom; }

.is-off-canvas-open {
  overflow: hidden; }

.js-off-canvas-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  background: rgba(254, 254, 254, 0.25);
  opacity: 0;
  visibility: hidden;
  overflow: hidden; }
  .js-off-canvas-overlay.is-visible {
    opacity: 1;
    visibility: visible; }
  .js-off-canvas-overlay.is-closable {
    cursor: pointer; }
  .js-off-canvas-overlay.is-overlay-absolute {
    position: absolute; }
  .js-off-canvas-overlay.is-overlay-fixed {
    position: fixed; }

.off-canvas-wrapper {
  position: relative;
  overflow: hidden; }

.off-canvas {
  position: fixed;
  z-index: 1;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #fefefe; }
  [data-whatinput='mouse'] .off-canvas {
    outline: 0; }
  .off-canvas.is-transition-overlap {
    z-index: 10; }
    .off-canvas.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.7); }
  .off-canvas.is-open {
    transform: translate(0, 0); }

.off-canvas-absolute {
  position: absolute;
  z-index: 1;
  transition: transform 0.5s ease;
  backface-visibility: hidden;
  background: #fefefe; }
  [data-whatinput='mouse'] .off-canvas-absolute {
    outline: 0; }
  .off-canvas-absolute.is-transition-overlap {
    z-index: 10; }
    .off-canvas-absolute.is-transition-overlap.is-open {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.7); }
  .off-canvas-absolute.is-open {
    transform: translate(0, 0); }

.position-left {
  top: 0;
  left: 0;
  width: 325px;
  height: 100%;
  transform: translateX(-325px);
  overflow-y: auto; }
  .position-left.is-open ~ .off-canvas-content {
    transform: translateX(325px); }
  .position-left.is-transition-push::after {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 1px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
    content: " "; }
  .position-left.is-transition-overlap.is-open ~ .off-canvas-content {
    transform: none; }

.position-right {
  top: 0;
  right: 0;
  width: 325px;
  height: 100%;
  transform: translateX(325px);
  overflow-y: auto; }
  .position-right.is-open ~ .off-canvas-content {
    transform: translateX(-325px); }
  .position-right.is-transition-push::after {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 1px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
    content: " "; }
  .position-right.is-transition-overlap.is-open ~ .off-canvas-content {
    transform: none; }

.position-top {
  top: 0;
  left: 0;
  width: 100%;
  height: 250px;
  transform: translateY(-250px);
  overflow-x: auto; }
  .position-top.is-open ~ .off-canvas-content {
    transform: translateY(250px); }
  .position-top.is-transition-push::after {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
    content: " "; }
  .position-top.is-transition-overlap.is-open ~ .off-canvas-content {
    transform: none; }

.position-bottom {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 250px;
  transform: translateY(250px);
  overflow-x: auto; }
  .position-bottom.is-open ~ .off-canvas-content {
    transform: translateY(-250px); }
  .position-bottom.is-transition-push::after {
    position: absolute;
    top: 0;
    left: 0;
    height: 1px;
    width: 100%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
    content: " "; }
  .position-bottom.is-transition-overlap.is-open ~ .off-canvas-content {
    transform: none; }

.off-canvas-content {
  transition: transform 0.5s ease;
  backface-visibility: hidden; }

@media print, screen and (min-width: 43.75em) {
  .position-left.reveal-for-medium {
    transform: none;
    z-index: 1; }
    .position-left.reveal-for-medium ~ .off-canvas-content {
      margin-left: 325px; }
  .position-right.reveal-for-medium {
    transform: none;
    z-index: 1; }
    .position-right.reveal-for-medium ~ .off-canvas-content {
      margin-right: 325px; }
  .position-top.reveal-for-medium {
    transform: none;
    z-index: 1; }
    .position-top.reveal-for-medium ~ .off-canvas-content {
      margin-top: 325px; }
  .position-bottom.reveal-for-medium {
    transform: none;
    z-index: 1; }
    .position-bottom.reveal-for-medium ~ .off-canvas-content {
      margin-bottom: 325px; } }

@media print, screen and (min-width: 64.375em) {
  .position-left.reveal-for-large {
    transform: none;
    z-index: 1; }
    .position-left.reveal-for-large ~ .off-canvas-content {
      margin-left: 325px; }
  .position-right.reveal-for-large {
    transform: none;
    z-index: 1; }
    .position-right.reveal-for-large ~ .off-canvas-content {
      margin-right: 325px; }
  .position-top.reveal-for-large {
    transform: none;
    z-index: 1; }
    .position-top.reveal-for-large ~ .off-canvas-content {
      margin-top: 325px; }
  .position-bottom.reveal-for-large {
    transform: none;
    z-index: 1; }
    .position-bottom.reveal-for-large ~ .off-canvas-content {
      margin-bottom: 325px; } }

.orbit {
  position: relative; }

.orbit-container {
  position: relative;
  height: 0;
  margin: 0;
  list-style: none;
  overflow: hidden; }

.orbit-slide {
  width: 100%; }
  .orbit-slide.no-motionui.is-active {
    top: 0;
    left: 0; }

.orbit-figure {
  margin: 0; }

.orbit-image {
  width: 100%;
  max-width: 100%;
  margin: 0; }

.orbit-caption {
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 0;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fefefe; }

.orbit-previous, .orbit-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  padding: 1rem;
  color: #fefefe; }
  [data-whatinput='mouse'] .orbit-previous, [data-whatinput='mouse'] .orbit-next {
    outline: 0; }
  .orbit-previous:hover, .orbit-next:hover, .orbit-previous:active, .orbit-next:active, .orbit-previous:focus, .orbit-next:focus {
    background-color: transparent; }

.orbit-previous {
  left: 0; }

.orbit-next {
  left: auto;
  right: 0; }

.orbit-bullets {
  position: relative;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  text-align: center; }
  [data-whatinput='mouse'] .orbit-bullets {
    outline: 0; }
  .orbit-bullets button {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0.1rem;
    border-radius: 50%;
    background-color: #fff; }
    .orbit-bullets button:hover {
      background-color: #1F8CEB; }
    .orbit-bullets button.is-active {
      background-color: #1F8CEB; }

.pagination {
  margin-left: 0;
  margin-bottom: 1rem; }
  .pagination::before, .pagination::after {
    display: table;
    content: ' '; }
  .pagination::after {
    clear: both; }
  .pagination li {
    margin-right: 0.0625rem;
    border-radius: 0;
    font-size: 0.875rem;
    display: none; }
    .pagination li:last-child, .pagination li:first-child {
      display: inline-block; }
    @media print, screen and (min-width: 43.75em) {
      .pagination li {
        display: inline-block; } }
  .pagination a,
  .pagination button {
    display: block;
    padding: 0.1875rem 0.625rem;
    border-radius: 0;
    color: #000; }
    .pagination a:hover,
    .pagination button:hover {
      background: #e6e6e6; }
  .pagination .current {
    padding: 0.1875rem 0.625rem;
    background: #cc7565;
    color: #fefefe;
    cursor: default; }
  .pagination .disabled {
    padding: 0.1875rem 0.625rem;
    color: #cacaca;
    cursor: not-allowed; }
    .pagination .disabled:hover {
      background: transparent; }
  .pagination .ellipsis::after {
    padding: 0.1875rem 0.625rem;
    content: '\2026';
    color: #000; }

.pagination-previous a::before,
.pagination-previous.disabled::before {
  display: inline-block;
  margin-right: 0.5rem;
  content: '\00ab'; }

.pagination-next a::after,
.pagination-next.disabled::after {
  display: inline-block;
  margin-left: 0.5rem;
  content: '\00bb'; }

.progress {
  height: 1rem;
  margin-bottom: 1rem;
  border-radius: 0;
  background-color: #cacaca; }
  .progress.primary .progress-meter {
    background-color: #cc7565; }
  .progress.secondary .progress-meter {
    background-color: #767676; }
  .progress.success .progress-meter {
    background-color: #3adb76; }
  .progress.warning .progress-meter {
    background-color: #ffae00; }
  .progress.alert .progress-meter {
    background-color: #cc4b37; }

.progress-meter {
  position: relative;
  display: block;
  width: 0%;
  height: 100%;
  background-color: #cc7565; }

.progress-meter-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  margin: 0;
  font-size: 0.75rem;
  font-weight: bold;
  color: #fefefe;
  white-space: nowrap; }

progress {
  display: block;
  width: 100%;
  height: 1rem;
  margin-bottom: 1rem;
  appearance: none;
  border-radius: 0;
  border: 0;
  background: #cacaca; }
  progress::-webkit-progress-bar {
    background: #cacaca;
    border-radius: 0; }
  progress::-webkit-progress-value {
    background: #cc7565;
    border-radius: 0; }
  progress::-moz-progress-bar {
    background: #cc7565;
    border-radius: 0; }
  progress.primary {
    color: #cc7565; }
    progress.primary::-webkit-progress-value {
      background: #cc7565; }
    progress.primary::-moz-progress-bar {
      background: #cc7565; }
  progress.secondary {
    color: #767676; }
    progress.secondary::-webkit-progress-value {
      background: #767676; }
    progress.secondary::-moz-progress-bar {
      background: #767676; }
  progress.success {
    color: #3adb76; }
    progress.success::-webkit-progress-value {
      background: #3adb76; }
    progress.success::-moz-progress-bar {
      background: #3adb76; }
  progress.warning {
    color: #ffae00; }
    progress.warning::-webkit-progress-value {
      background: #ffae00; }
    progress.warning::-moz-progress-bar {
      background: #ffae00; }
  progress.alert {
    color: #cc4b37; }
    progress.alert::-webkit-progress-value {
      background: #cc4b37; }
    progress.alert::-moz-progress-bar {
      background: #cc4b37; }
  progress::-ms-fill {
    border-radius: 0;
    border: 0; }

meter {
  display: block;
  width: 100%;
  height: 1rem;
  margin-bottom: 1rem;
  appearance: none;
  border: 0;
  background: #cacaca; }
  meter::-webkit-meter-bar {
    border: 0;
    background: #cacaca; }
  meter::-webkit-meter-optimum-value {
    background: #3adb76; }
  meter::-webkit-meter-suboptimum-value {
    background: #ffae00; }
  meter::-webkit-meter-even-less-good-value {
    background: #cc4b37; }
  meter::-moz-meter-bar {
    background: #cc7565; }
  meter:-moz-meter-optimum::-moz-meter-bar {
    background: #3adb76; }
  meter:-moz-meter-sub-optimum::-moz-meter-bar {
    background: #ffae00; }
  meter:-moz-meter-sub-sub-optimum::-moz-meter-bar {
    background: #cc4b37; }

.slider {
  position: relative;
  height: 0.5rem;
  margin-top: 1.25rem;
  margin-bottom: 2.25rem;
  background-color: #e6e6e6;
  cursor: pointer;
  user-select: none;
  touch-action: none; }

.slider-fill {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  max-width: 100%;
  height: 0.5rem;
  background-color: #cacaca;
  transition: all 0.2s ease-in-out; }
  .slider-fill.is-dragging {
    transition: all 0s linear; }

.slider-handle {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  z-index: 1;
  display: inline-block;
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 0;
  background-color: #cc7565;
  transition: all 0.2s ease-in-out;
  touch-action: manipulation; }
  [data-whatinput='mouse'] .slider-handle {
    outline: 0; }
  .slider-handle:hover {
    background-color: #c15643; }
  .slider-handle.is-dragging {
    transition: all 0s linear; }

.slider.disabled,
.slider[disabled] {
  opacity: 0.25;
  cursor: not-allowed; }

.slider.vertical {
  display: inline-block;
  width: 0.5rem;
  height: 12.5rem;
  margin: 0 1.25rem;
  transform: scale(1, -1); }
  .slider.vertical .slider-fill {
    top: 0;
    width: 0.5rem;
    max-height: 100%; }
  .slider.vertical .slider-handle {
    position: absolute;
    top: 0;
    left: 50%;
    width: 1.4rem;
    height: 1.4rem;
    transform: translateX(-50%); }

.sticky-container {
  position: relative; }

.sticky {
  position: relative;
  z-index: 0;
  transform: translate3d(0, 0, 0); }

.sticky.is-stuck {
  position: fixed;
  z-index: 5; }
  .sticky.is-stuck.is-at-top {
    top: 0; }
  .sticky.is-stuck.is-at-bottom {
    bottom: 0; }

.sticky.is-anchored {
  position: relative;
  right: auto;
  left: auto; }
  .sticky.is-anchored.is-at-bottom {
    bottom: 0; }

body.is-reveal-open {
  overflow: hidden; }

html.is-reveal-open,
html.is-reveal-open body {
  min-height: 100%;
  overflow: hidden;
  user-select: none; }

.reveal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: none;
  background-color: rgba(0, 0, 0, 0.45);
  overflow-y: scroll; }

.reveal {
  z-index: 1006;
  backface-visibility: hidden;
  display: none;
  padding: 1rem;
  border: 1px solid #cacaca;
  border-radius: 0;
  background-color: #fefefe;
  position: relative;
  top: 100px;
  margin-right: auto;
  margin-left: auto;
  overflow-y: auto; }
  [data-whatinput='mouse'] .reveal {
    outline: 0; }
  @media print, screen and (min-width: 43.75em) {
    .reveal {
      min-height: 0; } }
  .reveal .column, .reveal .columns,
  .reveal .columns {
    min-width: 0; }
  .reveal > :last-child {
    margin-bottom: 0; }
  @media print, screen and (min-width: 43.75em) {
    .reveal {
      width: 600px;
      max-width: 71.875rem; } }
  @media print, screen and (min-width: 43.75em) {
    .reveal .reveal {
      right: auto;
      left: auto;
      margin: 0 auto; } }
  .reveal.collapse {
    padding: 0; }
  @media print, screen and (min-width: 43.75em) {
    .reveal.tiny {
      width: 30%;
      max-width: 71.875rem; } }
  @media print, screen and (min-width: 43.75em) {
    .reveal.small {
      width: 50%;
      max-width: 71.875rem; } }
  @media print, screen and (min-width: 43.75em) {
    .reveal.large {
      width: 90%;
      max-width: 71.875rem; } }
  .reveal.full {
    top: 0;
    left: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    margin-left: 0;
    border: 0;
    border-radius: 0; }
  @media screen and (max-width: 43.6875em) {
    .reveal {
      top: 0;
      left: 0;
      width: 100%;
      max-width: none;
      height: 100%;
      height: 100vh;
      min-height: 100vh;
      margin-left: 0;
      border: 0;
      border-radius: 0; } }
  .reveal.without-overlay {
    position: fixed; }

.switch {
  height: 2rem;
  position: relative;
  margin-bottom: 1rem;
  outline: 0;
  font-size: 0.875rem;
  font-weight: bold;
  color: #fefefe;
  user-select: none; }

.switch-input {
  position: absolute;
  margin-bottom: 0;
  opacity: 0; }

.switch-paddle {
  position: relative;
  display: block;
  width: 4rem;
  height: 2rem;
  border-radius: 0;
  background: #cacaca;
  transition: all 0.25s ease-out;
  font-weight: inherit;
  color: inherit;
  cursor: pointer; }
  input + .switch-paddle {
    margin: 0; }
  .switch-paddle::after {
    position: absolute;
    top: 0.25rem;
    left: 0.25rem;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    transform: translate3d(0, 0, 0);
    border-radius: 0;
    background: #fefefe;
    transition: all 0.25s ease-out;
    content: ''; }
  input:checked ~ .switch-paddle {
    background: #cc7565; }
    input:checked ~ .switch-paddle::after {
      left: 2.25rem; }
  [data-whatinput='mouse'] input:focus ~ .switch-paddle {
    outline: 0; }

.switch-active, .switch-inactive {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.switch-active {
  left: 8%;
  display: none; }
  input:checked + label > .switch-active {
    display: block; }

.switch-inactive {
  right: 15%; }
  input:checked + label > .switch-inactive {
    display: none; }

.switch.tiny {
  height: 1.5rem; }
  .switch.tiny .switch-paddle {
    width: 3rem;
    height: 1.5rem;
    font-size: 0.625rem; }
  .switch.tiny .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1rem;
    height: 1rem; }
  .switch.tiny input:checked ~ .switch-paddle::after {
    left: 1.75rem; }

.switch.small {
  height: 1.75rem; }
  .switch.small .switch-paddle {
    width: 3.5rem;
    height: 1.75rem;
    font-size: 0.75rem; }
  .switch.small .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 1.25rem;
    height: 1.25rem; }
  .switch.small input:checked ~ .switch-paddle::after {
    left: 2rem; }

.switch.large {
  height: 2.5rem; }
  .switch.large .switch-paddle {
    width: 5rem;
    height: 2.5rem;
    font-size: 1rem; }
  .switch.large .switch-paddle::after {
    top: 0.25rem;
    left: 0.25rem;
    width: 2rem;
    height: 2rem; }
  .switch.large input:checked ~ .switch-paddle::after {
    left: 2.75rem; }

table {
  width: 100%;
  margin-bottom: 1rem;
  border-radius: 0; }
  table thead,
  table tbody,
  table tfoot {
    border: 1px solid #f1f1f1;
    background-color: #fefefe; }
  table caption {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: bold; }
  table thead {
    background: #f8f8f8;
    color: #4E515E; }
  table tfoot {
    background: #f1f1f1;
    color: #4E515E; }
  table thead tr,
  table tfoot tr {
    background: transparent; }
  table thead th,
  table thead td,
  table tfoot th,
  table tfoot td {
    padding: 0.5rem 0.625rem 0.625rem;
    font-weight: bold;
    text-align: left; }
  table tbody th,
  table tbody td {
    padding: 0.5rem 0.625rem 0.625rem; }
  table tbody tr:nth-child(even) {
    border-bottom: 0;
    background-color: #f1f1f1; }
  table.unstriped tbody {
    background-color: #fefefe; }
    table.unstriped tbody tr {
      border-bottom: 0;
      border-bottom: 1px solid #f1f1f1;
      background-color: #fefefe; }

@media screen and (max-width: 64.3125em) {
  table.stack thead {
    display: none; }
  table.stack tfoot {
    display: none; }
  table.stack tr,
  table.stack th,
  table.stack td {
    display: block; }
  table.stack td {
    border-top: 0; } }

table.scroll {
  display: block;
  width: 100%;
  overflow-x: auto; }

table.hover thead tr:hover {
  background-color: #f3f3f3; }

table.hover tfoot tr:hover {
  background-color: #ececec; }

table.hover tbody tr:hover {
  background-color: #f9f9f9; }

table.hover:not(.unstriped) tr:nth-of-type(even):hover {
  background-color: #ececec; }

.table-scroll {
  overflow-x: auto; }
  .table-scroll table {
    width: auto; }

.tabs {
  margin: 0;
  border: 1px solid transparent;
  background: #e6e6e6;
  list-style-type: none; }
  .tabs::before, .tabs::after {
    display: table;
    content: ' '; }
  .tabs::after {
    clear: both; }

.tabs.vertical > li {
  display: block;
  float: none;
  width: auto; }

.tabs.simple > li > a {
  padding: 0; }
  .tabs.simple > li > a:hover {
    background: transparent; }

.tabs.primary {
  background: #cc7565; }
  .tabs.primary > li > a {
    color: #000; }
    .tabs.primary > li > a:hover, .tabs.primary > li > a:focus {
      background: #c86b5a; }

.tabs-title {
  float: left; }
  .tabs-title > a {
    display: block;
    padding: 1.25rem 1.5rem;
    font-size: 0.875rem;
    line-height: 1;
    color: #cc7565; }
    .tabs-title > a:hover {
      background: #fefefe;
      color: #c15845; }
    .tabs-title > a:focus, .tabs-title > a[aria-selected='true'] {
      background: #fefefe;
      color: #cc7565; }

.tabs-content {
  border: 1px solid transparent;
  border-top: 0;
  background: #fefefe;
  color: #4E515E;
  transition: all 0.5s ease; }

.tabs-content.vertical {
  border: 1px solid transparent;
  border-left: 0; }

.tabs-panel {
  display: none;
  padding: 1rem; }
  .tabs-panel[aria-hidden="false"] {
    display: block; }

.thumbnail {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 1rem;
  border: solid 4px #fefefe;
  border-radius: 0;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2);
  line-height: 0; }

a.thumbnail {
  transition: box-shadow 200ms ease-out; }
  a.thumbnail:hover, a.thumbnail:focus {
    box-shadow: 0 0 6px 1px rgba(204, 117, 101, 0.5); }
  a.thumbnail image {
    box-shadow: none; }

.title-bar {
  padding: 0.5rem;
  background: #000;
  color: #fefefe; }
  .title-bar::before, .title-bar::after {
    display: table;
    content: ' '; }
  .title-bar::after {
    clear: both; }
  .title-bar .menu-icon {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }

.title-bar-left {
  float: left; }

.title-bar-right {
  float: right;
  text-align: right; }

.title-bar-title {
  display: inline-block;
  vertical-align: middle;
  font-weight: bold; }

.has-tip {
  position: relative;
  display: inline-block;
  border-bottom: dotted 1px #8a8a8a;
  font-weight: bold;
  cursor: help; }

.tooltip {
  position: absolute;
  top: calc(100% + 0.6495rem);
  z-index: 1200;
  max-width: 10rem;
  padding: 0.75rem;
  border-radius: 0;
  background-color: #000;
  font-size: 80%;
  color: #fefefe; }
  .tooltip::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-top-width: 0;
    border-bottom-style: solid;
    border-color: transparent transparent #000;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%); }
  .tooltip.top::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: #000 transparent transparent;
    top: 100%;
    bottom: auto; }
  .tooltip.left::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent #000;
    top: 50%;
    bottom: auto;
    left: 100%;
    transform: translateY(-50%); }
  .tooltip.right::before {
    display: block;
    width: 0;
    height: 0;
    border: inset 0.75rem;
    content: '';
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent #000 transparent transparent;
    top: 50%;
    right: 100%;
    bottom: auto;
    left: auto;
    transform: translateY(-50%); }

.top-bar {
  padding: 0.5rem; }
  .top-bar::before, .top-bar::after {
    display: table;
    content: ' '; }
  .top-bar::after {
    clear: both; }
  .top-bar,
  .top-bar ul {
    background-color: #e6e6e6; }
  .top-bar input {
    max-width: 200px;
    margin-right: 1rem; }
  .top-bar .input-group-field {
    width: 100%;
    margin-right: 0; }
  .top-bar input.button {
    width: auto; }
  .top-bar .top-bar-left,
  .top-bar .top-bar-right {
    width: 100%; }
  @media print, screen and (min-width: 43.75em) {
    .top-bar .top-bar-left,
    .top-bar .top-bar-right {
      width: auto; } }
  @media screen and (max-width: 64.3125em) {
    .top-bar.stacked-for-medium .top-bar-left,
    .top-bar.stacked-for-medium .top-bar-right {
      width: 100%; } }
  @media screen and (max-width: 74.9375em) {
    .top-bar.stacked-for-large .top-bar-left,
    .top-bar.stacked-for-large .top-bar-right {
      width: 100%; } }

.top-bar-title {
  display: inline-block;
  float: left;
  padding: 0.5rem 1rem 0.5rem 0; }
  .top-bar-title .menu-icon {
    bottom: 2px; }

.top-bar-left {
  float: left; }

.top-bar-right {
  float: right; }

.hide {
  display: none !important; }

.invisible {
  visibility: hidden; }

@media screen and (max-width: 43.6875em) {
  .hide-for-small-only {
    display: none !important; } }

@media screen and (max-width: 0em), screen and (min-width: 43.75em) {
  .show-for-small-only {
    display: none !important; } }

@media print, screen and (min-width: 43.75em) {
  .hide-for-medium {
    display: none !important; } }

@media screen and (max-width: 43.6875em) {
  .show-for-medium {
    display: none !important; } }

@media screen and (min-width: 43.75em) and (max-width: 64.3125em) {
  .hide-for-medium-only {
    display: none !important; } }

@media screen and (max-width: 43.6875em), screen and (min-width: 64.375em) {
  .show-for-medium-only {
    display: none !important; } }

@media print, screen and (min-width: 64.375em) {
  .hide-for-large {
    display: none !important; } }

@media screen and (max-width: 64.3125em) {
  .show-for-large {
    display: none !important; } }

@media screen and (min-width: 64.375em) and (max-width: 74.9375em) {
  .hide-for-large-only {
    display: none !important; } }

@media screen and (max-width: 64.3125em), screen and (min-width: 75em) {
  .show-for-large-only {
    display: none !important; } }

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0); }

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto; }

.show-for-landscape,
.hide-for-portrait {
  display: block !important; }
  @media screen and (orientation: landscape) {
    .show-for-landscape,
    .hide-for-portrait {
      display: block !important; } }
  @media screen and (orientation: portrait) {
    .show-for-landscape,
    .hide-for-portrait {
      display: none !important; } }

.hide-for-landscape,
.show-for-portrait {
  display: none !important; }
  @media screen and (orientation: landscape) {
    .hide-for-landscape,
    .show-for-portrait {
      display: none !important; } }
  @media screen and (orientation: portrait) {
    .hide-for-landscape,
    .show-for-portrait {
      display: block !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.clearfix::before, .clearfix::after {
  display: table;
  content: ' '; }

.clearfix::after {
  clear: both; }

.align-right {
  justify-content: flex-end; }

.align-center {
  justify-content: center; }

.align-justify {
  justify-content: space-between; }

.align-spaced {
  justify-content: space-around; }

.align-top {
  align-items: flex-start; }

.align-self-top {
  align-self: flex-start; }

.align-bottom {
  align-items: flex-end; }

.align-self-bottom {
  align-self: flex-end; }

.align-middle {
  align-items: center; }

.align-self-middle {
  align-self: center; }

.align-stretch {
  align-items: stretch; }

.align-self-stretch {
  align-self: stretch; }

.small-order-1 {
  order: 1; }

.small-order-2 {
  order: 2; }

.small-order-3 {
  order: 3; }

.small-order-4 {
  order: 4; }

.small-order-5 {
  order: 5; }

.small-order-6 {
  order: 6; }

@media print, screen and (min-width: 43.75em) {
  .medium-order-1 {
    order: 1; }
  .medium-order-2 {
    order: 2; }
  .medium-order-3 {
    order: 3; }
  .medium-order-4 {
    order: 4; }
  .medium-order-5 {
    order: 5; }
  .medium-order-6 {
    order: 6; } }

@media print, screen and (min-width: 64.375em) {
  .large-order-1 {
    order: 1; }
  .large-order-2 {
    order: 2; }
  .large-order-3 {
    order: 3; }
  .large-order-4 {
    order: 4; }
  .large-order-5 {
    order: 5; }
  .large-order-6 {
    order: 6; } }

.slide-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-down.mui-enter.mui-enter-active {
  transform: translateY(0); }

.slide-in-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(-100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-left.mui-enter.mui-enter-active {
  transform: translateX(0); }

.slide-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-up.mui-enter.mui-enter-active {
  transform: translateY(0); }

.slide-in-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(100%);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-in-right.mui-enter.mui-enter-active {
  transform: translateX(0); }

.slide-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-down.mui-leave.mui-leave-active {
  transform: translateY(100%); }

.slide-out-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-right.mui-leave.mui-leave-active {
  transform: translateX(100%); }

.slide-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateY(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-up.mui-leave.mui-leave-active {
  transform: translateY(-100%); }

.slide-out-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: translateX(0);
  transition-property: transform, opacity;
  backface-visibility: hidden; }

.slide-out-left.mui-leave.mui-leave-active {
  transform: translateX(-100%); }

.fade-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 0;
  transition-property: opacity; }

.fade-in.mui-enter.mui-enter-active {
  opacity: 1; }

.fade-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  opacity: 1;
  transition-property: opacity; }

.fade-out.mui-leave.mui-leave-active {
  opacity: 0; }

.hinge-in-from-top.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-top.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-right.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-right.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-bottom.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(90deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-bottom.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-left.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(90deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-left.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-x.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateX(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-middle-x.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-in-from-middle-y.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotateY(-90deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 0; }

.hinge-in-from-middle-y.mui-enter.mui-enter-active {
  transform: perspective(2000px) rotate(0deg);
  opacity: 1; }

.hinge-out-from-top.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: top;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-top.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hinge-out-from-right.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: right;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-right.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.hinge-out-from-bottom.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: bottom;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-bottom.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(90deg);
  opacity: 0; }

.hinge-out-from-left.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: left;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-left.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(90deg);
  opacity: 0; }

.hinge-out-from-middle-x.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-middle-x.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateX(-90deg);
  opacity: 0; }

.hinge-out-from-middle-y.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: perspective(2000px) rotate(0deg);
  transform-origin: center;
  transition-property: transform, opacity;
  opacity: 1; }

.hinge-out-from-middle-y.mui-leave.mui-leave-active {
  transform: perspective(2000px) rotateY(-90deg);
  opacity: 0; }

.scale-in-up.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(0.5);
  transition-property: transform, opacity;
  opacity: 0; }

.scale-in-up.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1; }

.scale-in-down.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1.5);
  transition-property: transform, opacity;
  opacity: 0; }

.scale-in-down.mui-enter.mui-enter-active {
  transform: scale(1);
  opacity: 1; }

.scale-out-up.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1; }

.scale-out-up.mui-leave.mui-leave-active {
  transform: scale(1.5);
  opacity: 0; }

.scale-out-down.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: scale(1);
  transition-property: transform, opacity;
  opacity: 1; }

.scale-out-down.mui-leave.mui-leave-active {
  transform: scale(0.5);
  opacity: 0; }

.spin-in.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(-0.75turn);
  transition-property: transform, opacity;
  opacity: 0; }

.spin-in.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1; }

.spin-out.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1; }

.spin-out.mui-leave.mui-leave-active {
  transform: rotate(0.75turn);
  opacity: 0; }

.spin-in-ccw.mui-enter {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0.75turn);
  transition-property: transform, opacity;
  opacity: 0; }

.spin-in-ccw.mui-enter.mui-enter-active {
  transform: rotate(0);
  opacity: 1; }

.spin-out-ccw.mui-leave {
  transition-duration: 500ms;
  transition-timing-function: linear;
  transform: rotate(0);
  transition-property: transform, opacity;
  opacity: 1; }

.spin-out-ccw.mui-leave.mui-leave-active {
  transform: rotate(-0.75turn);
  opacity: 0; }

.slow {
  transition-duration: 750ms !important; }

.fast {
  transition-duration: 250ms !important; }

.linear {
  transition-timing-function: linear !important; }

.ease {
  transition-timing-function: ease !important; }

.ease-in {
  transition-timing-function: ease-in !important; }

.ease-out {
  transition-timing-function: ease-out !important; }

.ease-in-out {
  transition-timing-function: ease-in-out !important; }

.bounce-in {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  transition-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  transition-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  transition-delay: 300ms !important; }

.long-delay {
  transition-delay: 700ms !important; }

.shake {
  animation-name: shake-7; }

@keyframes shake-7 {
  0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90% {
    transform: translateX(7%); }
  5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% {
    transform: translateX(-7%); } }

.spin-cw {
  animation-name: spin-cw-1turn; }

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(-1turn); }
  100% {
    transform: rotate(0); } }

.spin-ccw {
  animation-name: spin-cw-1turn; }

@keyframes spin-cw-1turn {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(1turn); } }

.wiggle {
  animation-name: wiggle-7deg; }

@keyframes wiggle-7deg {
  40%, 50%, 60% {
    transform: rotate(7deg); }
  35%, 45%, 55%, 65% {
    transform: rotate(-7deg); }
  0%, 30%, 70%, 100% {
    transform: rotate(0); } }

.shake,
.spin-cw,
.spin-ccw,
.wiggle {
  animation-duration: 500ms; }

.infinite {
  animation-iteration-count: infinite; }

.slow {
  animation-duration: 750ms !important; }

.fast {
  animation-duration: 250ms !important; }

.linear {
  animation-timing-function: linear !important; }

.ease {
  animation-timing-function: ease !important; }

.ease-in {
  animation-timing-function: ease-in !important; }

.ease-out {
  animation-timing-function: ease-out !important; }

.ease-in-out {
  animation-timing-function: ease-in-out !important; }

.bounce-in {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.24, 1.245) !important; }

.bounce-out {
  animation-timing-function: cubic-bezier(0.485, 0.155, 0.515, 0.845) !important; }

.bounce-in-out {
  animation-timing-function: cubic-bezier(0.76, -0.245, 0.24, 1.245) !important; }

.short-delay {
  animation-delay: 300ms !important; }

.long-delay {
  animation-delay: 700ms !important; }

.about-container {
  border-top: 1px solid #ede7df;
  background-color: #f6f3ef;
  position: relative; }
  .about-container h2, .about-container p, .about-container .label-title {
    z-index: 30;
    position: relative; }
  .about-container h3 {
    font-family: 'Apercu Bold',-apple-system, "system-ui", "Segoe font-family: 'Apercu Bold',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1.25rem; }
  .about-container .bio-mandi-1 {
    position: relative;
    margin-bottom: 1.875rem;
    margin-top: 0.5rem;
    height: auto;
    z-index: 21;
    border-radius: 0.5rem; }
  .about-container .pull-quote {
    margin-top: 1.75rem; }
    @media print, screen and (min-width: 43.75em) {
      .about-container .pull-quote {
        margin-top: 2.125rem;
        margin-bottom: 0; } }
  .about-container .contact-me {
    background: #DEDEE2;
    padding: 2rem 1.875rem 1.25rem 1.875rem;
    border-radius: 0.5rem;
    margin-top: 0.5rem;
    margin-bottom: 1.9375rem;
    z-index: 100;
    position: relative;
    text-align: center;
    margin-top: 2.5rem; }
    @media print, screen and (min-width: 43.75em) {
      .about-container .contact-me {
        text-align: left;
        margin-top: 0.5rem; } }
    .about-container .contact-me h2 {
      font-family: 'Apercu Bold',-apple-system, "system-ui", "Segoe font-family: 'Apercu Bold',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 1.5rem;
      margin-bottom: 1rem; }
  .about-container .circle-pattern {
    position: absolute;
    bottom: -3.125rem;
    left: 12.5rem;
    z-index: 20; }
    @media print, screen and (min-width: 43.75em) {
      .about-container .circle-pattern {
        left: 31.25rem;
        bottom: -6.25rem; } }
  .about-container .triangle-pattern {
    position: absolute;
    top: 3.125rem;
    left: -6.25rem;
    z-index: 20; }
    @media print, screen and (min-width: 43.75em) {
      .about-container .triangle-pattern {
        top: 4.6875rem;
        left: -10.9375rem; } }

@media screen and (max-width: 43.6875em) {
  .about-photo {
    margin-bottom: 2rem; } }

.about-positioning h3 {
  text-align: left; }

.button {
  margin-top: 0.375rem;
  font-family: 'Apercu Bold',-apple-system, "system-ui", "Segoe font-family: 'Apercu Bold',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1.0px;
  font-size: 0.75rem;
  color: #fff; }

.button.hollow {
  color: #cc7565;
  border: 1px solid #cc7565;
  background: transparent; }
  .button.hollow:active, .button.hollow:focus, .button.hollow:visited {
    color: #cc7565;
    border: 1px solid #cc7565;
    background: transparent; }

.button.hollow:hover {
  background: #cc7565;
  color: #fefefe !important;
  border: 1px solid #cc7565; }

.underline, .about p a {
  font-family: 'Apercu Bold',-apple-system, "system-ui", "Segoe font-family: 'Apercu Bold',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 0.875rem;
  color: #cc7565;
  border-bottom: 1.5px solid #cc7565;
  position: relative; }
  .underline:hover, .about p a:hover {
    color: #444B73;
    border-bottom: 1.5px solid #444B73;
    transition: all .2s ease-in-out; }

.case-study {
  background: #f6f3ef;
  border-bottom: 1px solid #ede7df;
  border-top: 1px solid #ede7df;
  padding-top: 3.5rem;
  padding-bottom: 2rem;
  text-align: left; }
  @media print, screen and (min-width: 43.75em) {
    .case-study {
      padding-bottom: 3rem; } }
  .case-study .sub-header {
    margin-bottom: 1.5rem; }
  .case-study .label-title {
    margin-bottom: 0.75rem;
    margin-top: 2rem;
    font-size: 1rem;
    text-align: left; }
    @media print, screen and (min-width: 43.75em) {
      .case-study .label-title {
        margin-top: 0; } }
  .case-study h2 {
    text-align: left;
    font-size: 1.5rem;
    font-family: 'Apercu Bold',-apple-system, "system-ui", "Segoe font-family: 'Apercu Bold',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    margin-bottom: 0.75rem; }
  .case-study .button {
    width: 6.25rem; }
  @media print, screen and (min-width: 43.75em) {
    .case-study img {
      width: 80%;
      float: right; } }

@media screen and (max-width: 43.6875em) {
  .case-studies {
    padding-bottom: 0; } }

@media print, screen and (min-width: 43.75em) {
  .case-studies {
    margin-top: -1.5rem; } }

.case-studies .label-title {
  text-align: center; }

.case-studies img {
  transition: all .25s ease-in-out; }
  .case-studies img:hover {
    transform: scale(1.1); }

.case-studies h3 {
  font-family: 'Apercu Bold',-apple-system, "system-ui", "Segoe font-family: 'Apercu Bold',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #444B73;
  margin-bottom: 1rem;
  line-height: 1.5;
  transition: all .2s ease-in-out;
  font-size: 1.125rem;
  text-align: center; }
  @media print, screen and (min-width: 43.75em) {
    .case-studies h3 {
      text-align: left; } }
  .case-studies h3:hover {
    color: #cc7565; }

.case-studies p {
  font-size: 1.125rem;
  line-height: 175%;
  color: #4E515E;
  font-family: 'Apercu Bold',-apple-system, "system-ui", "Segoe font-family: 'Apercu Bold',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  @media print, screen and (min-width: 43.75em) {
    .case-studies p {
      font-size: 1.25rem; } }

.case-study {
  margin-bottom: 0; }

@media screen and (max-width: 43.6875em) {
  .case-study-container {
    margin-bottom: 2.5rem; } }

.case-study .case-study-container img {
  border: 1px solid #ede7df;
  border-radius: 12px; }

@media screen and (max-width: 43.6875em) {
  .case-study-container.no-margin {
    margin-bottom: 0; } }

.case-study-container p {
  font-family: 'Apercu Regular',-apple-system, "system-ui", "Segoe font-family: 'Apercu Regular',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1.125rem; }

.case-study-container h3 {
  margin-bottom: 0; }

.case-study-container h3, .case-study-container p {
  text-align: center; }
  @media print, screen and (min-width: 43.75em) {
    .case-study-container h3, .case-study-container p {
      text-align: center; } }

.case-image-container {
  width: 100%;
  border-radius: 0.625rem;
  overflow: hidden;
  margin-bottom: 1.5rem;
  position: relative;
  z-index: 30;
  border: 1px solid #f0ece5; }
  .case-image-container .overlay {
    z-index: 30;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    border-radius: 0.875rem;
    transition: .5s ease;
    background: #cc7565;
    transition: all .2s ease-in-out;
    opacity: 0; }
    .case-image-container .overlay:hover {
      opacity: 0.95; }
    .case-image-container .overlay p {
      margin: 0 auto;
      padding-top: 40%;
      text-align: center;
      display: inline-block;
      color: #fefefe;
      width: 100%;
      display: inline-block;
      font-size: 1.25rem; }

.label.secondary {
  font-size: 0.625rem;
  color: #cc7565;
  background: #f8edeb;
  border: 1px solid #cc7565;
  border-radius: 0.25rem;
  text-transform: uppercase;
  font-family: 'Apercu Regular',-apple-system, "system-ui", "Segoe font-family: 'Apercu Regular',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  margin-bottom: 6px; }

.underline {
  padding-top: 2.25rem;
  display: inline-block; }

.case-study-hero {
  position: relative;
  z-index: 20; }
  @media print, screen and (min-width: 64.375em) {
    .case-study-hero {
      padding-top: 3.125rem;
      padding-bottom: 2.5rem; } }
  @media screen and (max-width: 43.6875em) {
    .case-study-hero {
      text-align: left;
      padding-bottom: 2rem; } }
  .case-study-hero h1 {
    font-family: 'Apercu Bold', -apple-system, "system-ui", "Segoe font-family: 'Apercu Bold',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1.5rem;
    line-height: 1.5;
    margin-bottom: 0.4rem; }
  .case-study-hero .sub-header {
    font-size: 1.125rem;
    font-family: 'Apercu Regular',-apple-system, "system-ui", "Segoe font-family: 'Apercu Regular',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    z-index: 25;
    position: relative;
    line-height: 1.5;
    text-align: left;
    margin-bottom: 1.5rem; }
    @media print, screen and (min-width: 43.75em) {
      .case-study-hero .sub-header {
        font-size: 1.25rem;
        margin-bottom: auto; } }
  .case-study-hero .button {
    margin-top: 1.5rem; }
  .case-study-hero img {
    margin-bottom: 4.125rem; }
  .case-study-hero ul {
    padding-bottom: 0; }
  .case-study-hero ul li {
    font-family: 'Apercu Regular',-apple-system, "system-ui", "Segoe font-family: 'Apercu Regular',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1.125rem;
    color: #4E515E; }
    @media screen and (max-width: 43.6875em) {
      .case-study-hero ul li {
        list-style: none;
        margin-left: -1.25rem; } }
    @media print, screen and (min-width: 43.75em) {
      .case-study-hero ul li {
        font-size: 1.25rem;
        margin-left: 0; } }
  .case-study-hero p {
    font-size: 1.125rem;
    line-height: 1.5; }
    @media print, screen and (min-width: 43.75em) {
      .case-study-hero p {
        font-size: 1rem; } }
  .case-study-hero .label-title {
    margin-bottom: 0.75rem;
    text-align: left;
    font-size: 1rem;
    line-height: 1.5; }
    @media print, screen and (min-width: 43.75em) {
      .case-study-hero .label-title {
        font-size: 1rem; } }
  .case-study-hero.excel-marketing {
    background: #E9F0FA;
    border-top: 1px solid #d4e2f5;
    border-bottom: 1px solid #d4e2f5; }
  .case-study-hero.bn {
    background: #FCECE7;
    border-top: 1px solid #f9dad0;
    border-bottom: 1px solid #f9dad0; }
  .case-study-hero.userzoom {
    background: #f6f3ef;
    border-top: 1px solid #ede7df;
    border-bottom: 1px solid #ede7df; }
  .case-study-hero.scheduler {
    background: #EDF3FF;
    border-top: 1px solid #d4e2ff;
    border-bottom: 1px solid #d4e2ff; }
  .case-study-hero.livesite {
    background: #F2F0FF;
    border-top: 1px solid #dcd7ff;
    border-bottom: 1px solid #dcd7ff; }

footer {
  background: #fefefe;
  position: relative;
  z-index: 25; }
  footer ul {
    min-height: 1.375rem;
    margin: 0.75rem;
    text-align: left; }
    @media screen and (max-width: 43.6875em) {
      footer ul {
        text-align: center; } }
  footer li {
    display: inline-block; }
    footer li img {
      width: 20px;
      height: 20px;
      margin-bottom: 0.25rem; }
    footer li a {
      list-style-type: none;
      font-family: 'Apercu Regular',-apple-system, "system-ui", "Segoe font-family: 'Apercu Bold',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      text-align: center;
      font-size: 0.875rem;
      color: #4E515E;
      transition: all .2s ease-in-out; }
      footer li a:hover {
        color: #cc7565; }

li a {
  margin-right: 1rem; }

@media screen and (min-width: 43.75em) {
  .bottom-footer-left {
    display: inline; } }

.bottom-footer-right {
  float: none; }
  @media screen and (min-width: 43.75em) {
    .bottom-footer-right {
      float: right; } }
  @media screen and (max-width: 43.6875em) {
    .bottom-footer-right.with-foundation a {
      margin-left: 0.5rem; } }

.home-hero {
  background: #f6f3ef;
  text-align: center;
  position: relative;
  border-bottom: 1px solid #ede7df; }
  .home-hero h1 {
    color: #444B73;
    font-family: 'Apercu Bold',-apple-system, "system-ui", "Segoe font-family: 'Apercu Bold',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    margin-top: 7rem;
    margin-bottom: 0;
    z-index: 25;
    position: relative;
    text-align: left;
    font-size: 1.5rem; }
    @media print, screen and (min-width: 43.75em) {
      .home-hero h1 {
        margin-top: 3.375rem;
        font-size: 1.375rem;
        text-align: center; } }
    @media print, screen and (min-width: 64.375em) {
      .home-hero h1 {
        margin-top: 5rem; } }
  .home-hero .subheader {
    font-size: 1.125rem;
    line-height: 1.5;
    color: #4E515E;
    font-family: 'Apercu Regular',-apple-system, "system-ui", "Segoe font-family: 'Apercu Regular',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    z-index: 25;
    position: relative;
    text-align: left;
    margin-top: 1rem; }
    @media print, screen and (min-width: 43.75em) {
      .home-hero .subheader {
        font-size: 1.375rem;
        margin-left: 0.125rem;
        text-align: center; } }

.mandi-hero {
  width: 90%;
  position: relative;
  margin-top: 5rem;
  margin-bottom: 0;
  height: auto;
  z-index: 25;
  border-radius: 0.625rem; }
  @media print, screen and (min-width: 43.75em) {
    .mandi-hero {
      margin-top: 2.125rem; } }
  @media print, screen and (min-width: 64.375em) {
    .mandi-hero {
      margin-top: 3.75rem; } }

@media screen and (max-width: 43.6875em) {
  .livesite-hero {
    background: url(../img/livesite-hero-small.png) no-repeat;
    background-size: cover;
    background-position: center;
    height: 26.5rem;
    width: 100%;
    padding-left: 0; } }

@media print, screen and (min-width: 43.75em) {
  .livesite-hero {
    background: url(../img/livesite-hero.png) no-repeat;
    background-size: cover;
    background-position: center;
    height: 31.25rem;
    margin-top: 3.75rem; } }

.circle-pattern {
  width: 21.875rem;
  height: auto;
  position: absolute;
  bottom: 0px;
  right: -200px;
  z-index: 0; }
  @media print, screen and (min-width: 43.75em) {
    .circle-pattern {
      bottom: 0px;
      right: 200px; } }

.triangle-pattern {
  width: 21.875rem;
  height: auto;
  position: absolute;
  top: -10px;
  left: -50px;
  z-index: 0; }
  @media print, screen and (min-width: 43.75em) {
    .triangle-pattern {
      top: -50px;
      left: 200px; } }

.bn-hero {
  margin: 0 0 -7px -2px; }
  .bn-hero video {
    border-bottom: 1px solid #E1E2E3;
    width: 100%;
    height: 100%;
    display: block; }
  @media screen and (max-width: 43.6875em) {
    .bn-hero {
      padding-top: 6.25rem;
      margin: 0 0 -10px -2px; } }

.excel-hero-image {
  position: relative;
  z-index: 2; }
  @media screen and (max-width: 43.6875em) {
    .excel-hero-image {
      padding-top: 2.5rem; } }

.excel-marketing-ipad {
  z-index: 7;
  position: relative;
  margin: 0 auto;
  margin-top: 4.125rem;
  display: block;
  width: 40.625rem;
  height: auto; }

.excel-marketing-industry {
  z-index: 7;
  position: absolute;
  display: block;
  right: 90px;
  top: 150px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  width: 10.4375rem; }

.excel-marketing-metric {
  z-index: 9;
  position: absolute;
  display: block;
  left: 20px;
  top: 300px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  width: 18.125rem; }

.excel-marketing-language-pink {
  z-index: 3;
  position: absolute;
  display: block;
  right: -50px;
  top: 230px; }
  @media screen and (max-width: 43.6875em) {
    .excel-marketing-language-pink {
      right: -5rem;
      top: 9.375rem; } }

.excel-marketing-language-blue {
  z-index: 2;
  position: absolute;
  display: block;
  left: 20px;
  top: 0px; }
  @media screen and (max-width: 43.6875em) {
    .excel-marketing-language-blue {
      left: -6.25rem;
      top: 1.25rem; } }

.scheduler-hero {
  background: linear-gradient(to bottom right, #7E9FD7, #344A7C);
  padding: 0; }

.scheduler-hero-image {
  z-index: 2;
  position: relative; }

.scheduler-hero-monitor {
  z-index: 7;
  display: block;
  margin-left: 312px;
  margin-top: 100px;
  width: 920px; }
  @media screen and (max-width: 43.6875em) {
    .scheduler-hero-monitor {
      margin-left: 0px;
      margin-top: 7.5rem;
      width: 1000px; } }

.scheduler-hero-login {
  z-index: 8;
  position: absolute;
  display: block;
  left: 78px;
  top: 138px;
  width: 268px; }

.scheduler-hero-form {
  z-index: 9;
  position: absolute;
  display: block;
  left: -102px;
  bottom: -112px;
  width: 448px; }

.grin-logo {
  width: 68px;
  margin-left: 4px;
  margin-bottom: 2px; }
  @media print, screen and (min-width: 43.75em) {
    .grin-logo {
      width: 80px;
      margin-left: 4px;
      margin-bottom: 4px; } }

.hero-network {
  margin-top: 1.5rem;
  text-align: left; }
  .hero-network img {
    margin-right: 0.75rem;
    width: 1.5rem; }

@media screen and (max-width: 43.6875em) {
  .userzoom-hero {
    background: url(../img/uz-hero-small.png) no-repeat;
    background-size: cover;
    background-position: center;
    height: 25rem;
    width: 100%;
    padding-left: 0;
    margin-bottom: -4px; } }

@media print, screen and (min-width: 43.75em) {
  .userzoom-hero {
    background: url(../img/uz-hero.png) no-repeat;
    background-size: cover;
    height: 28.125rem;
    margin-top: -4px; } }

.menu li a {
  padding: 0.125rem; }
  .menu li a.active {
    color: #444B73; }

a:hover, a:focus, a:active, a:visited {
  text-decoration: none; }

.navigation {
  background: #fefefe;
  border-bottom: 1px solid #E8E8E8;
  z-index: 200;
  display: block;
  position: fixed;
  width: 100%; }
  .navigation li {
    margin-right: 2.5rem;
    list-style-type: none;
    display: inline-block;
    font-size: 1rem; }

.navigation-links {
  font-family: 'Apercu Regular',-apple-system, "system-ui", "Segoe font-family: 'Apercu Regular',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  text-align: center;
  font-size: 1rem;
  color: #4E515E;
  border-top: 1px solid #e3e3e3; }
  @media screen and (min-width: 43.75em) {
    .navigation-links {
      border: 1px solid transparent; } }
  @media screen and (min-width: 64.375em) {
    .navigation-links {
      text-align: right; } }
  .navigation-links ul {
    text-align: center;
    margin: 0.75rem 0 0.75rem 0; }
    @media screen and (min-width: 43.75em) {
      .navigation-links ul {
        margin: 1.125rem;
        text-align: right; } }
  .navigation-links li {
    margin: 0px 8px; }
    @media screen and (min-width: 43.75em) {
      .navigation-links li {
        margin-left: 16px; } }
  .navigation-links li a {
    margin: 0px;
    color: #4E515E;
    transition: all .2s ease-in-out; }
    .navigation-links li a:hover {
      color: #cc7565; }

.mandi-logo {
  text-align: center;
  padding: 0.75rem; }
  @media print, screen and (min-width: 43.75em) {
    .mandi-logo {
      float: left;
      padding-top: 1rem;
      padding-left: 2rem; } }
  .mandi-logo a {
    font-family: 'Apercu Bold',-apple-system, "system-ui", "Segoe font-family: 'Apercu Bold',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    color: #cc7565;
    font-size: 1.25rem; }
    @media print, screen and (min-width: 43.75em) {
      .mandi-logo a {
        text-align: right; } }
    .mandi-logo a:hover {
      color: #444B73;
      transition: all 0.3s ease-in-out; }

.off-canvas-menu {
  font-size: 1.125rem;
  font-weight: 700; }
  .off-canvas-menu ul {
    padding: 0.5rem; }
  .off-canvas-menu li {
    list-style-type: none; }
  .off-canvas-menu a {
    color: #4E515E;
    line-height: 300%; }
    .off-canvas-menu a:focus {
      text-decoration: none; }
    .off-canvas-menu a.active {
      color: #444B73; }
  .off-canvas-menu hr {
    margin: 0.75rem auto 1.5rem auto; }
  .off-canvas-menu .off-canvas-menu-contact {
    line-height: 300%;
    color: #444B73; }
    .off-canvas-menu .off-canvas-menu-contact img {
      margin-right: 0.75rem; }

.navigation-mobile {
  z-index: 50;
  background: #fefefe;
  display: block;
  position: fixed;
  width: 100%;
  height: 7.5rem;
  border-bottom: 1px solid #E8E8E8;
  text-align: center; }
  .navigation-mobile .off-canvas-button {
    margin: 0.625rem;
    float: left;
    width: 1.625rem;
    height: 1.875rem;
    background: url("../img/navigation-icon.svg") no-repeat;
    background-size: cover; }

.off-canvas {
  display: none; }
  @media screen and (max-width: 43.6875em) {
    .off-canvas {
      display: inline; } }

#contact-excel-information:focus {
  outline: none; }

.resume {
  padding: 0; }
  .resume .row {
    padding: 0; }
  .resume .resume-title {
    font-size: 1.5rem; }
  .resume h1 {
    padding: 0;
    margin: 0;
    text-transform: none;
    margin-top: 7.5rem; }
    @media print, screen and (min-width: 43.75em) {
      .resume h1 {
        margin: 0; } }
  .resume h2 {
    text-align: left;
    color: #cc7565;
    font-size: 1.125rem;
    font-family: 'Apercu Bold',-apple-system, "system-ui", "Segoe font-family: 'Apercu Bold',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    margin-top: 2.5rem;
    margin-bottom: 1.25rem; }
  .resume h3 {
    letter-spacing: 0.06875rem;
    color: #444B73;
    text-transform: uppercase;
    font-size: 1rem;
    font-family: 'Apercu Regular',-apple-system, "system-ui", "Segoe font-family: 'Apercu Regular',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 700; }
    @media print, screen and (min-width: 43.75em) {
      .resume h3 {
        font-size: 1rem; } }
  .resume .resume-bullets {
    margin-bottom: 2rem; }
    .resume .resume-bullets li {
      list-style-type: disc;
      margin-left: 1.25rem;
      font-size: 1.125rem; }
  .resume ul {
    margin-left: 0; }
  .resume li {
    font-size: 1.125rem;
    list-style: none; }
  .resume .dates {
    letter-spacing: 0.03125rem;
    color: rgba(78, 81, 94, 0.6);
    font-size: 0.875rem;
    font-family: 'Apercu Regular',-apple-system, "system-ui", "Segoe font-family: 'Apercu Regular',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 700;
    margin-bottom: 0.75rem; }
    @media print, screen and (min-width: 43.75em) {
      .resume .dates {
        font-size: 0.9375rem; } }
  .resume p {
    margin-bottom: 2.25rem;
    font-size: 1.125rem; }
  .resume .left-panel {
    padding: 32px; }
    @media print, screen and (min-width: 43.75em) {
      .resume .left-panel {
        padding-right: 3.75rem;
        padding-top: 6.25rem;
        padding-bottom: 3.75rem; } }
  .resume .right-panel {
    padding: 0px 32px 32px 32px;
    background: rgba(204, 117, 101, 0.1); }
    @media print, screen and (min-width: 43.75em) {
      .resume .right-panel {
        padding-left: 3.75rem;
        padding-right: 3.75rem;
        padding-top: 12.5rem; } }

@font-face {
  font-family: 'Apercu Regular';
  src: url("../fonts/Apercu Regular.otf"); }

@font-face {
  font-family: 'Apercu Bold';
  src: url("../fonts/Apercu Bold.otf"); }

section {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem; }
  @media print, screen and (min-width: 43.75em) {
    section {
      padding-top: 3.125rem;
      padding-bottom: 3.125rem; } }
  @media print, screen and (min-width: 64.375em) {
    section {
      padding-top: 4.25rem;
      padding-bottom: 4.25rem; } }
  section:focus {
    outline: none; }

.about-callout {
  font-size: 1.375rem;
  text-align: left;
  font-family: 'Apercu Bold',-apple-system, "system-ui", "Segoe font-family: 'Apercu Bold',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  @media print, screen and (min-width: 43.75em) {
    .about-callout {
      font-size: 1.875rem; } }

.about-positioning h3 {
  font-size: 1.25rem;
  font-family: 'Apercu Bold',-apple-system, "system-ui", "Segoe font-family: 'Apercu Bold',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }

.about-positioning p {
  font-size: 1.125rem; }

.brand-link {
  display: inline-block;
  font-size: 1.25rem; }
  @media print, screen and (min-width: 64.375em) {
    .brand-link {
      margin-left: 0.75rem;
      padding-top: 0.75rem;
      border: 1px solid red; } }

.label-title {
  font-size: 1rem;
  color: #cc7565;
  text-align: center;
  margin-bottom: 0.75rem;
  font-family: 'Apercu Bold',-apple-system, "system-ui", "Segoe font-family: 'Apercu Bold',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  @media print, screen and (min-width: 43.75em) {
    .label-title {
      text-align: left; } }

h1 {
  font-family: 'Apercu Bold',-apple-system, "system-ui", "Segoe font-family: 'Apercu Bold',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #444B73;
  margin-bottom: 2.25rem; }

h2 {
  font-family: 'Apercu Bold',-apple-system, "system-ui", "Segoe font-family: 'Apercu Bold',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1.5rem;
  color: #444B73;
  margin-bottom: 1rem;
  line-height: 1.5; }
  @media screen and (max-width: 43.6875em) {
    h2 {
      text-align: center; } }

h3 {
  font-family: 'Apercu Bold',-apple-system, "system-ui", "Segoe font-family: 'Apercu Bold',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #444B73;
  font-size: 1.5rem; }

h4 {
  font-family: 'Apercu Bold',-apple-system, "system-ui", "Segoe font-family: 'Apercu Bold',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: #444B73; }

p, li {
  font-size: 1.125rem;
  color: #4E515E;
  font-family: 'Apercu Regular',-apple-system, "system-ui", "Segoe font-family: 'Apercu Regular',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }
  @media print, screen and (min-width: 43.75em) {
    p, li {
      font-size: 1.125rem; } }

.center-text {
  text-align: center; }

.inactive-h2:hover {
  color: #444B73 !important; }

.sub-header {
  font-family: 'Apercu Regular',-apple-system, "system-ui", "Segoe font-family: 'Apercu Regular',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1.25rem;
  color: #4E515E; }
  @media screen and (min-width: 43.75em) {
    .sub-header {
      text-align: left; } }
  .sub-header.textleft, .sub-header.text-left {
    text-align: left; }
  .sub-header.nobottom-space {
    margin-bottom: 0; }

.user-experience-image {
  margin-bottom: -3.75rem !important;
  width: 69.1875rem; }

.background img {
  margin-top: 2.25rem; }

.uz-background.background img {
  margin-top: 0; }

section.excel-user-feedback {
  background: url("../img/excel-wave-background.png") no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 75rem;
  margin-bottom: 12.5rem;
  margin-top: -1.25rem; }
  @media print, screen and (min-width: 43.75em) {
    section.excel-user-feedback {
      height: 78.75rem;
      margin-bottom: 12.5rem;
      margin-top: -3.75rem; } }

section.scheduler {
  background: #EDF3FF; }

section.bn-conclusion-background {
  background: url("../img/bn-conclusion-background.png") no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%; }
  @media screen and (max-width: 43.6875em) {
    section.bn-conclusion-background {
      padding-bottom: 0.75rem; } }
  section.bn-conclusion-background img {
    margin-top: 1.875rem; }

.excelmarketing {
  background: #D8E3F3; }

.excel-iphone {
  background: url("../img/excel-iphone.png") no-repeat;
  background-size: contain;
  background-position: center;
  height: 20rem !important;
  overflow: hidden;
  margin-bottom: -2.1875rem; }
  @media print, screen and (min-width: 43.75em) {
    .excel-iphone {
      height: 41.4375rem !important;
      background-size: cover;
      margin-bottom: -4.0625rem; } }

.excel-user-container {
  margin-top: 0;
  position: relative; }
  .excel-user-container .excel-usertesting-screen {
    text-align: center;
    display: block;
    margin: 0 auto;
    width: 40.625rem;
    height: auto; }
  .excel-user-container .excel-usertesting-comments {
    position: absolute;
    top: 3.125rem;
    left: 0;
    width: 100%; }

.about {
  background: #f6f3ef; }

a:active, a:visited, a:focus {
  text-decoration: none; }

.about .label-title {
  text-align: center; }
  @media print, screen and (min-width: 43.75em) {
    .about .label-title {
      text-align: left; } }

.about h2 {
  text-align: center; }
  @media print, screen and (min-width: 43.75em) {
    .about h2 {
      text-align: left; } }

.label-title a:active, .label-title a:visited, .label-title a:focus, a:focus {
  color: #cc7565 !important; }

.excel-background {
  background: #E9F0FA; }
  .excel-background img {
    margin-bottom: 3rem; }

.design-process {
  border: 1px solid #EBEBEB; }

.uz-background {
  background: #f6f3ef; }

.livesite-background {
  background: #F2F0FF; }

.uz-background-retro {
  background: #EEF4FB;
  border-bottom: 1px solid #E2E9F1; }

.brands {
  z-index: 30;
  margin-top: 1.875rem; }
  .brands .label-title {
    font-family: 'Apercu Bold',-apple-system, "system-ui", "Segoe font-family: 'Apercu Bold',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    text-align: center; }

.brand-logos {
  width: 40rem;
  height: auto;
  text-align: center;
  display: block;
  margin: 0 auto; }

.bn-background {
  background: rgba(252, 236, 231, 0.6); }

@media screen and (max-width: 43.6875em) {
  #about:before {
    content: "";
    height: 1.875rem;
    display: inline-block; } }

@media print, screen and (min-width: 43.75em) {
  #about:before {
    content: "";
    height: 0;
    display: inline-block; } }

.img-description {
  font-family: 'Apercu Regular',-apple-system, "system-ui", "Segoe font-family: 'Apercu Regular',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 0.75rem;
  color: rgba(78, 81, 94, 0.7);
  text-align: center;
  margin-top: 1.125rem;
  margin-bottom: 1.125rem; }
  @media print, screen and (min-width: 43.75em) {
    .img-description {
      font-size: 1rem; } }

.bn-movie {
  border: 2px solid transparent;
  width: 100%;
  overflow: hidden;
  margin: 0; }
  @media print, screen and (min-width: 43.75em) {
    .bn-movie {
      margin-left: -7px; } }
  @media print, screen and (min-width: 43.75em) {
    .bn-movie video {
      margin-left: -1px;
      margin-bottom: -8px;
      width: 102%; } }

.uximg-container {
  margin-bottom: 3.75rem; }

.wireframes-container {
  position: relative; }
  @media print, screen and (min-width: 43.75em) {
    .wireframes-container {
      padding-bottom: 33.125rem; } }
  .wireframes-container .wireframes-1, .wireframes-container .wireframes-2, .wireframes-container .wireframes-3 {
    position: relative;
    top: 0; }
    @media print, screen and (min-width: 43.75em) {
      .wireframes-container .wireframes-1, .wireframes-container .wireframes-2, .wireframes-container .wireframes-3 {
        position: absolute;
        top: 0; } }

@media screen and (max-width: 43.6875em) {
  .bn-filters {
    padding-bottom: 0;
    margin-bottom: -0.75rem; } }

.bn-usertesting .bn-usertesting-feedback {
  display: block;
  text-align: center;
  margin: 0 auto; }
  .bn-usertesting .bn-usertesting-feedback img {
    width: 64.5rem; }

.pull-quote {
  font-size: 1.375rem;
  color: #cc7565;
  font-family: 'Apercu Regular',-apple-system, "system-ui", "Segoe font-family: 'Apercu Regular',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  border-left: 4px solid #cc7565;
  padding: 0;
  padding-left: 1.625rem;
  margin: 2.25rem 0 2.25rem 0; }
  @media print, screen and (min-width: 43.75em) {
    .pull-quote {
      margin: 1.875rem 0 2.25rem 0; } }

.protected {
  margin-top: 8.75rem; }
  @media print, screen and (min-width: 43.75em) {
    .protected {
      margin-top: 6.25rem; } }
  .protected img {
    width: 1.125rem;
    margin-bottom: 2rem; }
    @media print, screen and (min-width: 43.75em) {
      .protected img {
        width: 1.5rem; } }
  .protected h1 {
    font-family: 'Apercu Bold',-apple-system, "system-ui", "Segoe font-family: 'Apercu Bold',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1.125rem;
    line-height: 1.5;
    color: #444B73;
    margin-bottom: 0.375rem; }
    @media print, screen and (min-width: 43.75em) {
      .protected h1 {
        font-size: 1.125rem; } }
  .protected p {
    font-family: 'Apercu Regular',-apple-system, "system-ui", "Segoe font-family: 'Apercu Regular',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1rem;
    line-height: 1.5;
    color: #4E515E;
    margin-bottom: 2rem; }
    @media print, screen and (min-width: 43.75em) {
      .protected p {
        font-size: 1.125rem; } }

#loom-companion-mv3, #shadow-host-companion {
  padding: 0 !important; }
