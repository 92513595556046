.case-study {
  background: rgba($cream, 1.0);
  border-bottom: 1px solid darken($cream, 5%);
  border-top: 1px solid darken($cream, 5%);
  padding-top: rem-calc(56);
  padding-bottom: rem-calc(32);
  text-align: left;

  @include breakpoint(medium) {
    padding-bottom: rem-calc(48);
  }

  .sub-header {
    margin-bottom: rem-calc(24);
  }

  .label-title  {
    margin-bottom: rem-calc(12);
    margin-top: rem-calc(32);
    font-size: rem-calc(16);
    text-align: left;

    @include breakpoint(medium) {
      margin-top: rem-calc(0);
    }
  }

  h2 {
    text-align: left;
    font-size: rem-calc(24);
    font-family: 'Apercu Bold',-apple-system, "system-ui", "Segoe font-family: 'Apercu Bold',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    margin-bottom: rem-calc(12);

    @include breakpoint(medium) {
    }
  }

  .button {
    width: rem-calc(100);
  }

  img {
    @include breakpoint(medium) {
      width: 80%;
      float: right;
    }
  }
}

// Index Page Case Study Tiles
.case-studies {
  @include breakpoint(small only) {
    padding-bottom: rem-calc(0);
  }

  @include breakpoint(medium) {
    margin-top: rem-calc(-24);
  }

  .label-title {
    text-align: center;
  }

  img {
    transition: all .25s ease-in-out;

    &:hover {
      transform: scale(1.10);
    }
  }

  h3 {
    font-family: 'Apercu Bold',-apple-system, "system-ui", "Segoe font-family: 'Apercu Bold',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    color: $dark-blue;
    margin-bottom: rem-calc(16);
    line-height: 1.5;
    transition: all .2s ease-in-out;
    font-size: rem-calc(18);
    text-align: center;

    @include breakpoint(medium) {
      text-align: left;
    }

    &:hover {
      color: $pink;
    }
  }

  p {
    font-size: rem-calc(18);
    line-height: 175%;
    color: $dark-grey;
    font-family: 'Apercu Bold',-apple-system, "system-ui", "Segoe font-family: 'Apercu Bold',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

    @include breakpoint(medium) {
      font-size: rem-calc(20);
    }
  }
}

.case-study {
  margin-bottom: 0;
}

.case-study-container {
  @include breakpoint(small only) {
    margin-bottom: rem-calc(40);
  }
}

.case-study .case-study-container img {
  border: 1px solid darken($cream, 5%);
  border-radius: 12px;
}

.case-study-container.no-margin {
  @include breakpoint(small only) {
    margin-bottom: rem-calc(0);
  }
}

.case-study-container p {
  font-family: 'Apercu Regular',-apple-system, "system-ui", "Segoe font-family: 'Apercu Regular',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: rem-calc(18);
}

.case-study-container h3 {
  margin-bottom: rem-calc(0);
}

.case-study-container h3, .case-study-container p {
  text-align: center;

  @include breakpoint(medium) {
    text-align: center;
  }
}

.case-image-container {
  width: 100%;
  border-radius: rem-calc(10);
  overflow: hidden;
  margin-bottom: rem-calc(24);
  position: relative;
  z-index: 30;
  border: 1px solid darken($cream, 3%);

  .overlay {
    z-index: 30;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    border-radius: rem-calc(14);
    transition: .5s ease;
    background: $pink;
    transition: all .2s ease-in-out;
    opacity: 0;

    &:hover {
      opacity: 0.95;
    }

    p {
      margin: 0 auto;
      padding-top: 40%;
      text-align: center;
      display: inline-block;
      color: $white;
      width: 100%;
      display: inline-block;
      font-size: rem-calc(20);
    }
  }
}

.label.secondary {
  font-size: rem-calc(10);
  color: $pink;
  background: lighten($pink, 35%);
  border: 1px solid $pink;
  border-radius: rem-calc(4);
  text-transform: uppercase;
  font-family: 'Apercu Regular',-apple-system, "system-ui", "Segoe font-family: 'Apercu Regular',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  margin-bottom: 6px;
}

.underline {
  padding-top: rem-calc(36);
  display: inline-block;
}

.case-study-hero {
  position: relative;
  z-index: 20;

  @include breakpoint(large) {
    padding-top: rem-calc(50);
    padding-bottom: rem-calc(40);
  }

  @include breakpoint(small only) {
    text-align: left;
    padding-bottom: rem-calc(32);
  }

  h1 {
    font-family: 'Apercu Bold', -apple-system, "system-ui", "Segoe font-family: 'Apercu Bold',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: rem-calc(24);
    line-height: 1.5;
    margin-bottom: 0.4rem;
  }

  .sub-header {
    font-size: rem-calc(18);
    font-family: 'Apercu Regular',-apple-system, "system-ui", "Segoe font-family: 'Apercu Regular',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    z-index: 25;
    position: relative;
    line-height: 1.5;
    text-align: left;
    margin-bottom: rem-calc(24);

    @include breakpoint(medium) {
      font-size: rem-calc(20);
      // line-height: 2;
      margin-bottom: auto;
    }
  }

  .button {
    margin-top: rem-calc(24);
  }

  img {
    margin-bottom: rem-calc(66);
  }

  ul {
    padding-bottom: 0;
  }

  ul li {
    font-family: 'Apercu Regular',-apple-system, "system-ui", "Segoe font-family: 'Apercu Regular',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: rem-calc(18);
    color: $dark-grey;

    @include breakpoint(small only) {
      list-style: none;
      margin-left: rem-calc(-20);
    }

    @include breakpoint(medium) {
      font-size: rem-calc(20);
      margin-left: rem-calc(0);
    }
  }

  p {
    font-size: rem-calc(18);
    line-height: 1.5;

    @include breakpoint(medium) {
      font-size: rem-calc(16);
    }
  }

  .label-title {
    margin-bottom: rem-calc(12);
    text-align: left;
    font-size: rem-calc(16);
    line-height: 1.5;

    @include breakpoint(medium) {
      font-size: rem-calc(16);
    }
  }

  &.excel-marketing {
    background: #E9F0FA;
    border-top: 1px solid darken(#E9F0FA, 5%);
    border-bottom: 1px solid darken(#E9F0FA, 5%);
  }

  &.bn {
    background: $bn-color;
    border-top: 1px solid darken($bn-color, 5%);
    border-bottom: 1px solid darken($bn-color, 5%);
  }

  &.userzoom {
    background: $cream;
    border-top: 1px solid darken($cream, 5%);
    border-bottom: 1px solid darken($cream, 5%);
  }

  &.scheduler {
    background: #EDF3FF;
    border-top: 1px solid darken(#EDF3FF, 5%);
    border-bottom: 1px solid darken(#EDF3FF, 5%);
  }

  &.livesite {
    background: #F2F0FF;
    border-top: 1px solid darken(#F2F0FF, 5%);
    border-bottom: 1px solid darken(#F2F0FF, 5%);
  }

}
