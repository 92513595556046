.about-container {
  border-top: 1px solid darken($cream, 5%);

  // @include breakpoint(small only) {
  //   padding-top: rem-calc(0);
  //   margin-top: rem-calc(0);
  // }

  background-color: $cream;
  position: relative;

  h2, p, .label-title {
    z-index: 30;
    position: relative;
  }

  h3 {
    font-family: 'Apercu Bold',-apple-system, "system-ui", "Segoe font-family: 'Apercu Bold',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: rem-calc(20);
  }

  .bio-mandi-1 {
    position: relative;
    margin-bottom: rem-calc(30);
    margin-top: rem-calc(8);
    height: auto;
    z-index: 21;
    border-radius: rem-calc(8);
  }

  .pull-quote {
    margin-top: rem-calc(28);

    @include breakpoint(medium) {
      margin-top: rem-calc(34);
      margin-bottom: rem-calc(0);
    }
  }

  .contact-me {
    background: #DEDEE2;
    padding: rem-calc(32 30 20 30);
    border-radius: rem-calc(8);
    margin-top: rem-calc(8);
    margin-bottom: rem-calc(31);
    z-index: 100;
    position: relative;
    text-align: center;
    margin-top: rem-calc(40);

    @include breakpoint(medium) {
      text-align: left;
      margin-top: rem-calc(8);
    }

    h2 {
      font-family: 'Apercu Bold',-apple-system, "system-ui", "Segoe font-family: 'Apercu Bold',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: rem-calc(24);
      margin-bottom: rem-calc(16);
    }
  }

  .circle-pattern {
    position: absolute;
    bottom: rem-calc(-50);
    left: rem-calc(200);
    z-index: 20;

    @include breakpoint(medium) {
      left: rem-calc(500);
      bottom: rem-calc(-100);
    }
  }

  .triangle-pattern {
    position: absolute;
    top: rem-calc(50);
    left: rem-calc(-100);
    z-index: 20;

    @include breakpoint(medium) {
      top: rem-calc(75);
      left: rem-calc(-175);
    }
  }
}

.about-photo {
  @include breakpoint(small only) {
    margin-bottom: rem-calc(32);
  }
}

.about-positioning h3 {
  text-align: left;
}
