.button {
  margin-top: rem-calc(6);
  font-family: 'Apercu Bold',-apple-system, "system-ui", "Segoe font-family: 'Apercu Bold',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 1.0px;
  font-size: rem-calc(12);
  color: #fff;
}

.button.hollow  {
  color: $primary-color;
  border: 1px solid $primary-color;
  background: transparent;

  &:active, &:focus, &:visited {
    color: $primary-color;
    border: 1px solid $primary-color;
    background: transparent;
  }
}

.button.hollow:hover {
  background: $primary-color;
  color: $white !important;
  border: 1px solid $primary-color;
}

.underline, .about p a {
  font-family: 'Apercu Bold',-apple-system, "system-ui", "Segoe font-family: 'Apercu Bold',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: rem-calc(14);
  color: $pink;
  border-bottom: 1.5px solid $pink;
  position: relative;

  &:hover {
    color: $dark-blue;
    border-bottom: 1.5px solid $dark-blue;
    transition: all .2s ease-in-out;
  }
}
