@font-face {
  font-family: 'Apercu Regular';
  src: url('../fonts/Apercu Regular.otf');
}

@font-face {
  font-family: 'Apercu Bold';
  src: url('../fonts/Apercu Bold.otf');
}

section {
  padding-top: rem-calc(40);
  padding-bottom: rem-calc(40);

  @include breakpoint(medium) {
    padding-top: rem-calc(50);
    padding-bottom: rem-calc(50);
  }

  @include breakpoint(large) {
    padding-top: rem-calc(68);
    padding-bottom: rem-calc(68);
  }

  &:focus {
    outline: none;
  }
}

.about-callout {
  font-size: rem-calc(22);
  text-align: left;
  font-family: 'Apercu Bold',-apple-system, "system-ui", "Segoe font-family: 'Apercu Bold',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

  @include breakpoint(medium) {
    font-size: rem-calc(30);
  }
}

.about-positioning h3 {
  font-size: rem-calc(20);
  font-family: 'Apercu Bold',-apple-system, "system-ui", "Segoe font-family: 'Apercu Bold',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.about-positioning p {
  font-size: rem-calc(18);
}

.brand-link {
  display: inline-block;
  font-size: rem-calc(20);

  @include breakpoint(large) {
    margin-left: rem-calc(12);
    padding-top: rem-calc(12);
    border: 1px solid red;
  }
}

.label-title {
  font-size: rem-calc(16);
  color: $pink;
  text-align: center;
  margin-bottom: rem-calc(12);
  font-family: 'Apercu Bold',-apple-system, "system-ui", "Segoe font-family: 'Apercu Bold',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

  @include breakpoint(medium) {
    text-align: left;
  }
}

h1 {
  font-family: 'Apercu Bold',-apple-system, "system-ui", "Segoe font-family: 'Apercu Bold',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: $dark-blue;
  margin-bottom: rem-calc(36);
}

h2 {
  font-family: 'Apercu Bold',-apple-system, "system-ui", "Segoe font-family: 'Apercu Bold',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: rem-calc(24);
  color: $dark-blue;
  margin-bottom: rem-calc(16);
  line-height: 1.5;

  @include breakpoint(small only) {
    text-align: center;
  }
}

h3 {
  font-family: 'Apercu Bold',-apple-system, "system-ui", "Segoe font-family: 'Apercu Bold',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: $dark-blue;
  font-size: rem-calc(24);
}

h4 {
  font-family: 'Apercu Bold',-apple-system, "system-ui", "Segoe font-family: 'Apercu Bold',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  color: $dark-blue;
}

p, li {
  font-size: rem-calc(18);
  color: $dark-grey;
  font-family: 'Apercu Regular',-apple-system, "system-ui", "Segoe font-family: 'Apercu Regular',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

  @include breakpoint(medium) {
    font-size: rem-calc(18);
  }
}
.center-text {
  text-align: center;
}

.inactive-h2:hover {
  color: $dark-blue !important;
}

.sub-header {
  font-family: 'Apercu Regular',-apple-system, "system-ui", "Segoe font-family: 'Apercu Regular',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: rem-calc(20);
  color: $dark-grey;

  @include breakpoint(medium up) {
    text-align: left;
  }

  &.textleft, &.text-left {
    text-align: left;
  }

  &.nobottom-space {
    margin-bottom: 0;
  }
}

.user-experience-image {
  margin-bottom: rem-calc(-60) !important;
  width: rem-calc(1107);
}

.background img {
  margin-top: rem-calc(36);
}

.uz-background.background img {
  margin-top: rem-calc(0);
}

section.excel-user-feedback {
  background: url('../img/excel-wave-background.png') no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: rem-calc(1200);
  margin-bottom: rem-calc(200);
  margin-top: rem-calc(-20);

  @include breakpoint(medium) {
    height: rem-calc(1260);
    margin-bottom: rem-calc(200);
    margin-top: rem-calc(-60);
  }
}

section.scheduler {
  background: #EDF3FF;
}

section.bn-conclusion-background {
  background: url('../img/bn-conclusion-background.png') no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;

  @include breakpoint(small only) {
    padding-bottom: rem-calc(12);
  }

  img {
    margin-top: rem-calc(30);
  }
}

.excelmarketing {
  background: #D8E3F3;
}

.excel-iphone {
  background: url('../img/excel-iphone.png') no-repeat;
  background-size: contain;
  background-position: center;
  height: rem-calc(320)!important;
  overflow: hidden;
  margin-bottom: rem-calc(-35);

  @include breakpoint(medium) {
    height: rem-calc(663) !important;
    background-size: cover;
    margin-bottom: rem-calc(-65);
  }
}

.excel-user-container {
  margin-top: rem-calc(0);
  position: relative;

  .excel-usertesting-screen {
    text-align: center;
    display: block;
    margin: 0 auto;
    width: rem-calc(650);
    height: auto;
  }

  .excel-usertesting-comments {
    position: absolute;
    top: rem-calc(50);
    left: 0;
    width: 100%;
  }
}

.about {
  background: $cream;
}

a:active, a:visited, a:focus {
  text-decoration: none;
}

.about .label-title {
  text-align: center;

  @include breakpoint(medium) {
    text-align: left;
  }
}

.about h2{
  text-align: center;

  @include breakpoint(medium) {
    text-align: left;
  }
}

.label-title a:active, .label-title a:visited, .label-title a:focus, a:focus {
  color: $pink !important;
}

.excel-background {
  background: #E9F0FA;

  img {
    margin-bottom: rem-calc(48);
  }
}

.design-process {
  border: 1px solid #EBEBEB;
}

.uz-background {
  background: $cream;
}

.livesite-background {
  background: #F2F0FF;
}

.uz-background-retro {
  background: #EEF4FB;
  border-bottom: 1px solid #E2E9F1;
}
//
// .uz-background.background .label-title {
//   margin-top: rem-calc(36);
//
//   @include breakpoint(large) {
//     margin-top: rem-calc(100);
//   }
// }

.brands {
  z-index: 30;
  margin-top: rem-calc(30);

  .label-title {
    font-family: 'Apercu Bold',-apple-system, "system-ui", "Segoe font-family: 'Apercu Bold',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    text-align: center;
  }
}

.brand-logos {
  width: rem-calc(640);
  height: auto;
  text-align: center;
  display: block;
  margin: 0 auto;
}

.bn-background {
  background: rgba($bn-color, 0.60);
}

//Temp fix for anchor problem created by fixed header
#about {
  @include breakpoint(small only) {
    &:before {
      content: "";
      height: rem-calc(30);
      display: inline-block;
    }
  }

  @include breakpoint(medium) {
    &:before {
      content: "";
      height: rem-calc(0);
      display: inline-block;
    }
  }
}

.img-description {
  font-family: 'Apercu Regular',-apple-system, "system-ui", "Segoe font-family: 'Apercu Regular',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: rem-calc(12);
  color: rgba($dark-grey, 0.70);
  text-align: center;
  margin-top: rem-calc(18);
  margin-bottom: rem-calc(18);

  @include breakpoint(medium) {
    font-size: rem-calc(16);
  }
}

.bn-movie {
  border: 2px solid transparent;
  width: 100%;
  overflow: hidden;
  margin: 0;

  @include breakpoint(medium) {
    margin-left: -7px;
  }

  video {

    @include breakpoint(medium) {
      margin-left: -1px;
      margin-bottom: -8px;
      width: 102%;
    }
  }
}

.uximg-container {
  margin-bottom: rem-calc(60);
}

.wireframes-container {
  position: relative;

  @include breakpoint(medium) {
    padding-bottom: rem-calc(530);
  }

  .wireframes-1, .wireframes-2, .wireframes-3 {
    position: relative;
    top: 0;

    @include breakpoint(medium) {
      position: absolute;
      top: 0;
    }
  }
}

.bn-filters {
  @include breakpoint(small only) {
    padding-bottom: 0;
    margin-bottom: rem-calc(-12);
  }
}

.bn-usertesting .bn-usertesting-feedback {
  display: block;
  text-align: center;
  margin: 0 auto;

  img {
    width: rem-calc(1032);
  }
}

.bn-usertesting {
  img {
    // margin-bottom: rem-calc(26);
  }
}

.pull-quote {
  font-size: rem-calc(22);
  color: $pink;
  font-family: 'Apercu Regular',-apple-system, "system-ui", "Segoe font-family: 'Apercu Regular',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  border-left: 4px solid $pink;
  padding: 0;
  padding-left: rem-calc(26);
  margin: rem-calc(36 0 36 0);

  @include breakpoint(medium) {
    margin: rem-calc(30 0 36 0);
  }
}

.protected {
  margin-top: rem-calc(140);

  @include breakpoint(medium) {
    margin-top: rem-calc(100);
  }

  img {
    width: rem-calc(18);
    margin-bottom: rem-calc(32);

    @include breakpoint(medium) {
      width: rem-calc(24);
    }
  }

  h1 {
    font-family: 'Apercu Bold',-apple-system, "system-ui", "Segoe font-family: 'Apercu Bold',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: rem-calc(18);
    line-height: 1.5;
    color: $dark-blue;
    margin-bottom: rem-calc(6);

    @include breakpoint(medium) {
      font-size: rem-calc(18);
    }
  }

  p {
    font-family: 'Apercu Regular',-apple-system, "system-ui", "Segoe font-family: 'Apercu Regular',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: rem-calc(16);
    line-height: 1.5;
    color: $dark-grey;
    margin-bottom: rem-calc(32);

    @include breakpoint(medium) {
      font-size: rem-calc(18);
    }
  }
}

#loom-companion-mv3, #shadow-host-companion {
  padding: 0 !important;
}
