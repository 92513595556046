.resume {
  padding: 0;

  .row {
    padding: 0;
  }

  .resume-title {
    font-size: rem-calc(24);
  }

  h1 {
    padding: rem-calc(0);
    margin: rem-calc(0);
    text-transform: none;
    margin-top: rem-calc(120);

    @include breakpoint(medium) {
      margin: rem-calc(0);
    }
  }

  h2 {
    text-align: left;
    color: $pink;
    font-size: rem-calc(18);
    font-family: 'Apercu Bold',-apple-system, "system-ui", "Segoe font-family: 'Apercu Bold',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    margin-top: rem-calc(40);
    margin-bottom: rem-calc(20);
  }

  h3 {
    letter-spacing: rem-calc(1.10);
    color: $dark-blue;
    text-transform: uppercase;
    font-size: rem-calc(16);
    font-family: 'Apercu Regular',-apple-system, "system-ui", "Segoe font-family: 'Apercu Regular',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 700;

    @include breakpoint(medium) {
      font-size: rem-calc(16);
    }
  }

  .resume-bullets {
    margin-bottom: rem-calc(32);


    li {
      list-style-type: disc;
      margin-left: rem-calc(20);
      font-size: rem-calc(18);
    }
  }

  ul {
    margin-left: 0;
  }

  li {
    font-size: rem-calc(18);
    list-style: none;

  }

  .dates {
    letter-spacing: rem-calc(0.5);
    color: rgba($dark-grey, 0.60);
    font-size: rem-calc(14);
    font-family: 'Apercu Regular',-apple-system, "system-ui", "Segoe font-family: 'Apercu Regular',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 700;
    margin-bottom: rem-calc(12);

    @include breakpoint(medium) {
      font-size: rem-calc(15);
    }
  }

  p {
    margin-bottom: rem-calc(36);
    font-size: rem-calc(18);
  }

  .left-panel {
    padding: 32px;

    @include breakpoint(medium) {
      padding-right: rem-calc(60);
      padding-top: rem-calc(100);
      padding-bottom: rem-calc(60);
    }
  }

  .right-panel {
    padding: 0px 32px 32px 32px;
    background: rgba($pink, 0.10);

    @include breakpoint(medium) {
      padding-left: rem-calc(60);
      padding-right: rem-calc(60);
      padding-top: rem-calc(200);
    }
  }
}
