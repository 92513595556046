footer {
  background: $white;
  position: relative;
  z-index: 25;

  ul {
    min-height: rem-calc(22);
    margin: rem-calc(12);
    text-align: left;

    @include breakpoint(small only) {
      text-align: center;
    }
  }

  li {
    display: inline-block;

    img {
      width: 20px;
      height: 20px;
      margin-bottom: rem-calc(4);
    }

    a {
      list-style-type: none;
      font-family: 'Apercu Regular',-apple-system, "system-ui", "Segoe font-family: 'Apercu Bold',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      text-align: center;
      font-size: rem-calc(14);
      color: $dark-grey;
      transition: all .2s ease-in-out;

      &:hover {
        color: $pink;
      }
    }
  }
}

li a {
  margin-right: rem-calc(16);
}

.bottom-footer-left {
  @include breakpoint(medium up) {
    display: inline;
  }
}

.bottom-footer-right {
  float: none;

  @include breakpoint(medium up) {
    float: right;
  }

  &.with-foundation a {
    @include breakpoint(small only) {
      margin-left: rem-calc(8);
    }
  }
}
