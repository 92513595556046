.home-hero {
  background: $cream;
  text-align: center;
  position: relative;
  border-bottom: 1px solid darken($cream, 5%);

  // @include breakpoint(medium) {
  //   text-align: left;
  // }

  h1 {
    color: $dark-blue;
    font-family: 'Apercu Bold',-apple-system, "system-ui", "Segoe font-family: 'Apercu Bold',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    margin-top: rem-calc(112);
    margin-bottom: rem-calc(0);
    z-index: 25;
    position: relative;
    text-align: left;
    font-size: rem-calc(24);

    @include breakpoint(medium) {
      margin-top: rem-calc(54);
      font-size: rem-calc(22);
      text-align: center;
    }

    @include breakpoint(large) {
      margin-top: rem-calc(80);
    }
  }

  .subheader {
    font-size: rem-calc(18);
    line-height: 1.5;
    color: $dark-grey;
    font-family: 'Apercu Regular',-apple-system, "system-ui", "Segoe font-family: 'Apercu Regular',UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    z-index: 25;
    position: relative;
    text-align: left;
    margin-top: rem-calc(16);

    @include breakpoint(medium) {
      font-size: rem-calc(22);
      margin-left: rem-calc(2);
      text-align: center;
    }
  }
}

.mandi-hero {
  width: 90%;
  position: relative;
  margin-top: rem-calc(80);
  margin-bottom: rem-calc(0);
  height: auto;
  z-index: 25;
  border-radius: rem-calc(10);

  @include breakpoint(medium) {
    margin-top: rem-calc(34);
  }

  @include breakpoint(large) {
    margin-top: rem-calc(60);
  }
}

.livesite-hero {
  @include breakpoint(small only) {
    background: url(../img/livesite-hero-small.png) no-repeat;
    background-size: cover;
    background-position: center;
    height: rem-calc(424);
    width: 100%;
    padding-left: 0;
  }

  @include breakpoint(medium) {

    background: url(../img/livesite-hero.png) no-repeat;
    background-size: cover;
    background-position: center;
    height: rem-calc(500);
    margin-top: rem-calc(60);
  }
}

.circle-pattern {
  width: rem-calc(350);
  height: auto;
  position: absolute;
  bottom: 0px;
  right: -200px;
  z-index: 0;

  @include breakpoint(medium) {
    bottom: 0px;
    right: 200px;
  }
}

.triangle-pattern {
  width: rem-calc(350);
  height: auto;
  position: absolute;
  top: -10px;
  left: -50px;
  z-index: 0;

  @include breakpoint(medium) {
    top: -50px;
    left: 200px;
  }
}

.bn-hero {
  margin: 0 0 -7px -2px;

  video {
    border-bottom: 1px solid #E1E2E3;
    width: 100%;
    height: 100%;
    display: block;
  }

  @include breakpoint(small only) {
    padding-top: rem-calc(100);
    margin: 0 0 -10px -2px;
  }
}

.excel-hero-image {
  position: relative;
  z-index: 2;

  @include breakpoint(small only) {
    padding-top: rem-calc(40);
  }
}

.excel-marketing-ipad {
  z-index: 7;
  position: relative;
  margin: 0 auto;
  margin-top: rem-calc(66);
  display: block;
  width: rem-calc(650);
  height: auto;
}

.excel-marketing-industry {
  z-index: 7;
  position: absolute;
  display: block;
  right: 90px;
  top: 150px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  width: rem-calc(167);
}

.excel-marketing-metric {
  z-index: 9;
  position: absolute;
  display: block;
  left: 20px;
  top: 300px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  width: rem-calc(290);
}

.excel-marketing-language-pink {
  z-index: 3;
  position: absolute;
  display: block;
  right: -50px;
  top: 230px;

  @include breakpoint(small only) {
    right: rem-calc(-80);
    top: rem-calc(150);
  }
}

.excel-marketing-language-blue {
  z-index: 2;
  position: absolute;
  display: block;
  left: 20px;
  top: 0px;

  @include breakpoint(small only) {
    left: rem-calc(-100);
    top: rem-calc(20);
  }
}

.scheduler-hero {
  background: linear-gradient(to bottom right, #7E9FD7, #344A7C);
  padding: 0;
}

.scheduler-hero-image {
  z-index: 2;
  position: relative;
}

.scheduler-hero-monitor {
  z-index: 7;
  display: block;
  margin-left: 312px;
  margin-top: 100px;
  width: 920px;

  @include breakpoint(small only) {
    margin-left: 0px;
    margin-top: rem-calc(120);
    width: 1000px;
  }
}

.scheduler-hero-login {
  z-index: 8;
  position: absolute;
  display: block;
  left: 78px;
  top: 138px;
  width: 268px;
}

.scheduler-hero-form {
  z-index: 9;
  position: absolute;
  display: block;
  left: -102px;
  bottom: -112px;
  width: 448px;
}

.grin-logo {
  width: 68px;
  margin-left: 4px;
  margin-bottom: 2px;

  @include breakpoint(medium) {
    width: 80px;
    margin-left: 4px;
    margin-bottom: 4px;
  }
}

.hero-network {
  margin-top: rem-calc(24);
  text-align: left;

  img {
    margin-right: rem-calc(12);
    width: rem-calc(24);
  }
}

.userzoom-hero {
  @include breakpoint(small only) {
    background: url(../img/uz-hero-small.png) no-repeat;
    background-size: cover;
    background-position: center;
    height: rem-calc(400);
    width: 100%;
    padding-left: 0;
    margin-bottom: -4px;
  }

  @include breakpoint(medium) {
    background: url(../img/uz-hero.png) no-repeat;
    background-size: cover;
    height: rem-calc(450);
    margin-top: -4px;
  }
}
